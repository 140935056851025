import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom'
import EmblaCarousel from './EmblaCarousel/EmblaCarousel'
import "./EmblaCarousel/embla.css"
const OPTIONS = { axis: 'y', loop: true }

function Footer() {
    const server = process.env.REACT_APP_APIPATH;
    const [data, setData] = useState([]);
    const [IsLoading, setIsLoading] = useState(true);

    const fillData = async () => {
        try {
            const response = await axios.get(`${server}api/Client/ImageGallery10`);
            const json = await response.data;
            setData(json);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fillData();
    }, []);

    return (<footer className="wide-tb-70 pb-0 mb-spacer-md txt-secondary">
        <div className="container pos-rel">
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="logo-footer">
                        <img src="ROSHNI-LOGO.png" alt="" />
                    </div>
                    <p style={{ textAlign: "justify" }}>Roshni Education Trust is dedicated to the betterment of Muslim society. Everything we do here revolves around the basis of Islam. Roshni Education Trust has come into force. It stands for the ultimate motive to serve Humanity in the best possible way.</p>
                    <div className="social-icons">
                        <ul className="list-unstyled list-group list-group-horizontal">
                            <li><a href="https://m.facebook.com/ruminurseryschool" target="_blank" rel="noreferrer"><i className="icofont-facebook"></i></a></li>
                            <li><a href="https://instagram.com/ruminurseryschool?igshid=NGExMmI2YTkyZg==" target="_blank" rel="noreferrer"><i className="icofont-instagram"></i></a></li>
                            <li><a href="https://youtube.com/@RoshniEducationTrustSurat.?feature=shared" target="_blank" rel="noreferrer"><i className="icofont-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h3 className="footer-heading">Contact Info</h3>
                    <div className="footer-widget-contact">
                        <ul className="list-unstyled">
                            <li>
                                <div><i data-feather="map-pin"></i> </div>
                                <div>Godha Street, Opp. LIC Building, Near Khalifa Street, Nanpura, Surat</div>
                            </li>
                            <li>
                                <div><i data-feather="phone"></i> </div>
                                <div><a href="tel:+917861809679">+91 78618 09679 (Roshni)</a></div>
                            </li>
                            <li>
                                <div><i data-feather="phone"></i> </div>
                                <div><a href="tel:+916354904216">+91 63549 04216 (Rumi)</a></div>
                            </li>
                            <li>
                                <div><i data-feather="mail"></i> </div>
                                <div><a href="mailto:info@roshnieducationtrust.org"><span
                                    className="__cf_email__">info@roshnieducationtrust.org</span></a></div>
                            </li>
                            <li>
                                <div><i data-feather="clock"></i> </div>
                                <div>Mon-Sat / 11:00 AM - 05:00 PM</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="w-100 d-none d-md-block d-lg-none spacer-30" />
                <div className="col-lg-2 col-md-6">
                    <h3 className="footer-heading">Explore</h3>
                    <div className="footer-widget-menu">
                        <ul className="list-unstyled">
                            <li><NavLink to="/"><i className="icofont-simple-right"></i> <span>Home</span></NavLink></li>
                            <li><NavLink to="/about-us"><i className="icofont-simple-right"></i> <span>About Us</span></NavLink></li>
                            <li><NavLink to="/our-vision"><i className="icofont-simple-right"></i> <span>Our Vision</span></NavLink></li>
                            <li><NavLink to="/causes"><i className="icofont-simple-right"></i> <span>Causes</span></NavLink></li>
                            <li><NavLink to="/events"><i className="icofont-simple-right"></i> <span>Events</span></NavLink></li>
                            <li><NavLink to="/contact"><i className="icofont-simple-right"></i> <span>Contact Us</span></NavLink></li>
                            <li><NavLink to="/gallery"><i className="icofont-simple-right"></i> <span>Gallery</span></NavLink></li>
                            <li><NavLink to="/donate-us"><i className="icofont-simple-right"></i> <span>Donate</span></NavLink></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h3 className="footer-heading">Photo Gallery</h3>
                    <ul id="basicuse" className="photo-thumbs">
                        {IsLoading && Array.from({ length: 6 }, () => {
                            return (
                                <li>
                                    <Skeleton height={85} width={75} borderRadius={5} />
                                </li>
                            );
                        })}
                        {!IsLoading && <EmblaCarousel slides={data} options={OPTIONS} />}
                    </ul>
                </div>
            </div>
        </div>
        <div className="copyright-wrap">
            <div className="container pos-rel">
                <div className="row text-md-start text-center">
                    <div className="col-sm-12 col-md-auto copyright-text">
                        © Copyright <span className="txt-blue">Roshni Educatuion Trust</span> <span>{new Date().getFullYear()}</span>.
                        | Created by  &nbsp;
                        <a href="#" rel="nofollow">
                            DARC Technologies
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
}

export default Footer