import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function Navbar() {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 991); // Adjust the threshold as needed
        };

        

        // Initial check on mount
        handleResize();

        if(window.innerWidth <= 991){
            document.querySelector(".navbar2").classList.add('fixed','animated','fadeInDown','fixed-top');
            document.querySelector(".navbar2").style.position ="fixed";
        }
        
        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNavbarToggleClick = () => {
        document.querySelector('html').classList.toggle('sidenav-open');
    };

    useEffect(() => {
        const handleTriggerClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const offcanvasId = e.currentTarget.getAttribute('data-trigger');
            const offcanvasElement = document.querySelector(offcanvasId);
            offcanvasElement.classList.toggle('show');
            document.querySelector('html').classList.toggle('offcanvas-active');
            document.querySelector('.screen-overlay').classList.toggle('show');
        };

        try {
            document.querySelectorAll('[data-trigger]').forEach((trigger) => {
                trigger.addEventListener('click', handleTriggerClick);
            });
        } catch (err) {
            console.error(err);
        }

        // Cleanup event listeners on component unmount
        return () => {
            document.querySelectorAll('[data-trigger]').forEach((trigger) => {
                trigger.removeEventListener('click', handleTriggerClick);
            });
        };
    }, []);


    return (
        <>
            <header >
                <div className="navbar navbar-expand-lg header-fullpage">
                    <div className="container text-nowrap">
                        <div className="d-flex align-items-center w-100 col p-0 logo-brand">
                            <NavLink className="navbar-brand rounded-bottom light-bg" to="/">
                                <img src="ROSHNI-LOGO.webp" alt="" />
                            </NavLink>
                        </div>
                        <div className="d-inline-flex request-btn order-lg-last col-auto p-0 align-items-center">
                            <NavLink
                                className="nav-link btn btn-default ms-3 donate-btn"
                                to="/donate-us"
                                style={{fontSize:"x-large",padding:".5rem 1rem"}}
                            >
                                Donate
                            </NavLink>
                            <button
                                className="navbar-toggler x collapsed"
                                onClick={() => handleNavbarToggleClick()}
                                type="button"
                                data-bs-toggle="collapse"
                                data-target="#navbarCollapse"
                                aria-controls="navbarCollapse"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                        </div>
                    </div>
                </div>
                <nav className="navbar2 navbar-expand-lg header-fullpage">
                    <div className="container text-nowrap p-0">
                        <div className="navbar-collapse" style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <div className="offcanvas-header">
                                <NavLink to="/" className="logo-small">
                                    <img src="./ROSHNI-LOGO.webp" alt="" />
                                </NavLink>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav ms-auto me-auto">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/" onClick={() => handleNavbarToggleClick()}>Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/about-us" onClick={() => handleNavbarToggleClick()}>About Us</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/our-vision" onClick={() => handleNavbarToggleClick()}>Our Vision</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/causes" onClick={() => handleNavbarToggleClick()}>Causes</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/events" onClick={() => handleNavbarToggleClick()}>Events</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/gallery" onClick={() => handleNavbarToggleClick()}>Gallery</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/contact" onClick={() => handleNavbarToggleClick()}>Contact Us</NavLink>
                                    </li>
                                    {isSmallScreen &&
                                        <li className="nav-item">
                                            <NavLink
                                                className="btn btn-default"
                                                to="/donate-us"
                                                onClick={() => handleNavbarToggleClick()}
                                                style={{ margin: "3", textAlign: "end", textTransform: "uppercase", display: "flex", justifyContent: "center" }}
                                            >
                                                Donate
                                            </NavLink>
                                        </li>
                                    }
                                    {/* {!isSmallScreen && */}
                                        <li className="nav-item">
                                            <p className='txt-orange' style={{fontSize:"22px"}}>Our Socials : 
                                                <a href="https://m.facebook.com/ruminurseryschool" target="_blank" className='socials' rel="noreferrer"><i class="icofont-facebook"></i></a>
                                                <a href="https://instagram.com/ruminurseryschool?igshid=NGExMmI2YTkyZg==" target="_blank"  className='socials' rel="noreferrer"><i class="icofont-instagram"></i></a>
                                                <a href="https://youtube.com/@RoshniEducationTrustSurat.?feature=shared" target="_blank"  className='socials' rel="noreferrer"><i class="icofont-youtube"></i></a>
                                                </p>
                                        </li>
                                    {/* } */}
                                </ul>
                            </div>
                            <div className="close-nav" onClick={() => handleNavbarToggleClick()} />
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Navbar
