import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import Viewer from 'react-viewer';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

function EventWithPhotos({ id }) {
    const server = process.env.REACT_APP_APIPATH;
    const [data, setData] = useState([]);
    const [images, setImages] = useState([]);
    const [activeIndex, setAtiveIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [date, setDate] = useState(null);
    const fillData = async () => {
        try {
            const response = await axios.get(`${server}api/Client/event/${id}`);
            const json = await response.data;
            setData(json);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fillData();
    }, []);

    useEffect(() => {
        setDate(new moment(convertDateToIndianFormat(data.eventDate)));
        var imgs = !isLoading && data.eventPhotos.length > 0 && data.eventPhotos.map((photo, index) => {
            return { src: server + photo.photo, alt: data.eventName + " " + (index + 1) }
        });
        setImages(imgs);
    }, [data]);

    function convertDateToIndianFormat(utcTimestamp) {
        const date = new Date(utcTimestamp);

        // Calculate the offset in minutes
        const offsetInMinutes = date.getTimezoneOffset();

        // Adjust for IST by subtracting the offset from the Date object
        const istDateTime = new Date(date.getTime() - offsetInMinutes * 60000);

        return istDateTime;
    }


    return (
        <>
            <main id="body-content">
                <section className="wide-tb-100">
                    <div className="container">
                        {
                            isLoading && <Skeleton height={390} />
                        }
                        {!isLoading &&
                            <>
                                <div className="row">
                                    <div className="col-lg-7">
                                        <img src={server + data.eventPhoto} alt="" />
                                    </div>
                                    <div class="col-lg-5 col-md-12">
                                        <div class="icon-box-4 bg-gray mb-4">
                                            <h2>Event :-</h2>
                                            <h1>{data.eventName}</h1>
                                            <br />
                                            <h2>Date :-</h2>
                                            <h1>{date.date()} <span>{date.format("MMM")},{date.format("YYYY")}</span></h1>
                                            <br />
                                            <h2>Time :-</h2>
                                            <h1>{date.format("dddd")}, {date.format('h:mm a')}</h1>
                                        </div>
                                        {!isLoading && data.eventPhotos.length > 0 && <h1 style={{textAlign:"center",fontSize:"45px"}} className="heading-main mb-lg-0 mt-5">
                                                    Photos Of The Event
                                        </h1>}
                                    </div>
                                </div>
                            </>
                        }
                        {!isLoading && data.eventPhotos.length > 0 &&
                            <>
                                <Viewer
                                    visible={visible}
                                    onClose={() => { setVisible(false); }}
                                    images={images}
                                    onMaskClick={() => { setVisible(false); }}
                                    activeIndex={activeIndex}
                                />
                                <section className="wide-tb-100">
                                    <div className="container">
                                        <div className="row img-gallery">
                                            <ResponsiveMasonry  columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}} >
                                                <Masonry gutter="10px">
                                                    {!isLoading &&
                                                        data.eventPhotos.map(photo => {
                                                            return (
                                                                <div key={photo.rowNumber} onClick={() => {setVisible(true);setAtiveIndex(photo.rowNumber-1)}}>
                                                                    <div className="img-gallery-item">
                                                                        <a
                                                                            // href={server + photo.photo}
                                                                            title={data.eventName}
                                                                        >
                                                                            <img src={server + photo.photo} alt="" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </Masonry>
                                            </ResponsiveMasonry>
                                        </div>
                                    </div>
                                </section>
                            </>
                        }
                    </div>
                </section>
            </main>
        </>
    )
}

export default EventWithPhotos
