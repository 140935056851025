import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateAmount } from '../Redux/Reducer'
import { useNavigate } from 'react-router-dom';
import Toast, { showToast } from './Toast';

function DonationAmount() {
    const amount = useSelector(state => state.paymentDetails.amount);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function redirectToDetails() {
        if (!isNaN(amount) && +amount > 0) {
            navigate('details');
        } else {
            showToast('Please select a donation amount', 'error', 2000);
        }
    }

    return (
        <div className='mt-1'>
            <Toast />
            <h3 className="h3-sm fw-5 txt-blue mb-3">
                Select Your Donation Amount
            </h3>
            <div className="row">
                <div className="col-md-9">
                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input form-light"
                                type="radio"
                                name="Amount"
                                id="amount1"
                                value={100}
                                checked={amount == 100}
                                onChange={e => dispatch(updateAmount(e.target.value))}
                            />
                            <label
                                className="form-check-label label-dark"
                                htmlFor="amount1"
                            >
                                ₹100
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input form-light"
                                type="radio"
                                name="Amount"
                                id="amount2"
                                value={200}
                                checked={amount == 200}
                                onChange={e => dispatch(updateAmount(e.target.value))}
                            />
                            <label
                                className="form-check-label label-dark"
                                htmlFor="amount2"
                            >
                                ₹200
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input form-light"
                                type="radio"
                                name="Amount"
                                id="amount4"
                                value={400}
                                checked={amount == 400}
                                onChange={e => dispatch(updateAmount(e.target.value))}
                            />
                            <label
                                className="form-check-label label-dark"
                                htmlFor="amount4"
                            >
                                ₹400
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input form-light"
                                type="radio"
                                name="Amount"
                                id="amount5"
                                value={500}
                                checked={amount == 500}
                                onChange={e => dispatch(updateAmount(e.target.value))}
                            />
                            <label
                                className="form-check-label label-dark"
                                htmlFor="amount5"
                            >
                                ₹500
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input form-light"
                                type="radio"
                                name="Amount"
                                id="amount6"
                                value={1000}
                                checked={amount == 1000}
                                onChange={e => dispatch(updateAmount(e.target.value))}
                            />
                            <label
                                className="form-check-label label-dark"
                                htmlFor="amount6"
                            >
                                ₹1000
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input form-light"
                                type="radio"
                                name="Amount"
                                id="amount7"
                                value={5000}
                                checked={amount == 5000}
                                onChange={e => dispatch(updateAmount(e.target.value))}
                            />
                            <label
                                className="form-check-label label-dark"
                                htmlFor="amount7"
                            >
                                ₹5000
                            </label>
                        </div>
                        <div className="mt-3">
                            <input
                                type="number"
                                min={0}
                                className="form-control"
                                id="txtAmount"
                                placeholder="Custom Amount"
                                value={amount}
                                onChange={e => dispatch(updateAmount(e.target.value))}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mt-auto">
                    <div className="form-group">
                        <button type="button" onClick={() => redirectToDetails()} className="btn btn-default" style={{ width: '100%', borderRadius: '5px', padding: "12px", bottom: 0 }} >
                            DONATE
                        </button>
                    </div>
                </div>
                <div className="col-md-12">
                    <p className="text-primary" style={{fontSize: '14px'}}>
                        Terms & Conditions: <br />
                        Before you donate please read the following points: <br />
                        1. Only Indian Residents and NRI can donate, we are not accepting donations from any foreign account. NRI can only donate through an NRO account and not their foreign account. <br />
                        2. Donor will get their receipt on their Email ID. <br />
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DonationAmount
