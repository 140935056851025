import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';

function NewsArticles() {
    const server = process.env.REACT_APP_APIPATH;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fillData = async () => {
        try {
            const response = await axios.get(`${server}api/Client/NewsArticles`);
            const json = await response.data;
            setData(json);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fillData();
    }, []);

    useEffect(() => {
        if (data.length > 0 && !isLoading) {
            window.jQuery("#home-new-slider").owlCarousel({
                items: 1,
                margin: 30,
                loop: true,
                nav: true,
                slideBy: 1,
                dots: false,
                center: false,
                autoplay: true,
                autoheight: true,
                navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>'],
                responsive: {
                    320: {
                        items: 1,
                    },
                    600: {
                        items: 1,
                    },
                    767: {
                        items: 2,
                    },
                    1000: {
                        items: 3,
                        loop: true,
                    },
                    1200: {
                        items: 3,
                        loop: true,
                    },
                    1920: {
                        items: 3,
                        loop: true,
                    }
                }
            });
        }
    }, [isLoading]);


    if (isLoading) {
        return (
            <div>
                <section className="wide-tb-30 bg-white mb-5 pb-0 home-blog-post-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-lg-6 col-9">
                                <h1 className="heading-main">
                                    <small><Skeleton /></small>
                                    <Skeleton />
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            {
                                Array.from({ length: 3 }, () => {
                                    return (
                                        <div className="col-sm-12 col-md-6 col-lg-4">
                                            <div className="item">
                                                <Skeleton height={400} width={300} />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </section >
            </div >
        );
    }

    return (
        <div>
            <section className="wide-tb-30 bg-white pb-0 home-blog-post-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-lg-6 col-9">
                            <h1 className="heading-main">
                                <small>News &amp; Blogs</small>
                                Some Of Our Recent Stories &amp; News Blog
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="owl-carousel owl-theme" id="home-new-slider">
                                {data.map(article => {
                                    return (<div className="item">
                                        <div className="post-wrap">
                                            <div className="post-img">
                                                <a href={article.articleLink} target='_blank' rel='noreferrer'>
                                                    <img src={server + article.photo} alt="" />
                                                </a>
                                            </div>
                                            <div className="post-content">
                                                <div className="post-date">{article.toDate}</div>
                                                <h3 className="post-title">
                                                    <a href={article.articleLink} target='_blank' rel='noreferrer'>
                                                        {article.title}
                                                    </a>
                                                </h3>
                                                <div className="post-category">{article.description.substring(0,Math.min(article.description.length,120)) }{Math.min(article.description.length,120) === 120 && "..."}</div>
                                                <div className="text-md-end">
                                                    <a href={article.articleLink} target='_blank' rel='noreferrer' className="read-more-line">
                                                        <span>Read More</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default NewsArticles
