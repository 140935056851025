import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'

function CausesComp({ server, data, isLoading, replaceSpaceWithDash }) {

    if (isLoading) {
        return (
            <div className="row">
                {
                    Array.from({ length: 3 }, () => {
                        return (
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <Skeleton height={300} />
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    return (<>
        <h1 className="heading-main">
            Our Causes
        </h1>
        <div className="row">
            {data.map(cause => {
                return (<div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="causes-wrap">
                        <div className="img-wrap">
                            <NavLink to={replaceSpaceWithDash(cause.causeName)}>
                                <img src={server + cause.photo} alt="" />
                            </NavLink>
                        </div>
                        <div className="content-wrap">
                            <h3>
                                <NavLink to={replaceSpaceWithDash(cause.causeName)}>{cause.causeName}</NavLink>
                            </h3>
                            <div className="btn-wrap">
                                <NavLink className="btn btn-primary" style={{ borderRadius: "8px" }} to={replaceSpaceWithDash(cause.causeName)}>
                                    Read More
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>);
            })}

        </div>
    </>
    )
}

export default CausesComp
