import React from 'react'
import { NavLink } from 'react-router-dom'

function NotFound404() {
    return (
        <div>
            <main id="body-content" className="w-100 error-style">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <h1 className="heading-main">
                                <small>404 Error</small>
                                Oops!
                            </h1>
                            <p>Something is broken, please try again later or go to home page</p>
                            <NavLink className="btn btn-primary mt-4" to="">
                                Back to home
                            </NavLink>
                        </div>
                        <div className="col-lg-9">
                            <div className="text-center error-img">
                                <img src="assets/images/404_img.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default NotFound404
