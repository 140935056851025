import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

function SliderHome() {

    useEffect(() => {
        try {
            const $ = window.jQuery;

            // Your jQuery code here
            $(".main-banner .owl-carousel").on("initialized.owl.carousel", () => {
                setTimeout(() => {
                    $(".owl-item.active .owl-slide-animated").addClass("is-transitioned");
                    $("section").show();
                }, 200);
            });

            const $owlCarousel = $(".main-banner .owl-carousel").owlCarousel({
                items: 1,
                loop: true,
                autoplay: true,
                autoplayHoverPause: true,
                mouseDrag: false,
                autoHeight: true,
                nav: true,
                dots: false,
                navText: [
                    '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
                    '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
                ],
            });

            $owlCarousel.on("changed.owl.carousel", (e) => {
                $(".owl-slide-animated").removeClass("is-transitioned");
                const $currentOwlItem = $(".owl-item").eq(e.item.index);
                $currentOwlItem.find(".owl-slide-animated").addClass("is-transitioned");
                const $target = $currentOwlItem.find(".owl-slide-text");
                doDotsCalculations($target);
            });

            $owlCarousel.on("resize.owl.carousel", () => {
                setTimeout(() => {
                    setOwlDotsPosition();
                }, 50);
            });

            setOwlDotsPosition();

            function setOwlDotsPosition() {
                const $target = $(".owl-item.active .owl-slide-text");
                doDotsCalculations($target);
            }

            function doDotsCalculations(el) {
                const height = el.height();
                const { top, left } = el.position();
                const res = height + top + 20;
                $(".main-banner .owl-carousel .owl-dots").css({
                    top: `${res}px`,
                    left: `${left}px`,
                });
            }

            // Cleanup function
            return () => {
                // Remove event listeners or perform any cleanup here
                $(".main-banner .owl-carousel").off("initialized.owl.carousel");
                $owlCarousel.off("changed.owl.carousel");
                $owlCarousel.off("resize.owl.carousel");
                $owlCarousel.owlCarousel("destroy");
            };
        }
        catch (err) {
            console.log(err);
        }
    }, []);

    return (
        <div>
            <section className="main-banner home-style-second" style={{ maxHeight: "600px" }}>
                <div className="slides-wrap">
                    <div className="owl-carousel owl-theme">
                        <div
                            className="owl-slide d-flex align-items-center cover"
                            style={{
                                backgroundImage: "url(assets/images/banner3.webp)"
                            }}
                        >
                            <div className="container">
                                <div className="row justify-content-center justify-content-md-start no-gutters">
                                    <div className="col-10 col-md-6 static">
                                        <div className="owl-slide-text">
                                            <h3 className="owl-slide-animated owl-slide-title moving-bg-text">
                                                Your path to success begins here.
                                            </h3>
                                            <h2 className="owl-slide-animated owl-slide-subtitle moving-bg-text">
                                                ADMISSION NOW OPEN.
                                            </h2>
                                            <div className="owl-slide-animated owl-slide-cta">
                                                <NavLink
                                                    className="btn btn-default me-3"
                                                    to="/donate-us"
                                                    role="button"
                                                >
                                                    Make a Difference
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="owl-slide d-flex align-items-center cover"
                            style={{
                                backgroundImage: "url(assets/images/banner4.webp)"
                            }}
                        >
                            <div className="container">
                                <div className="row justify-content-center justify-content-md-start no-gutters">
                                    <div className="col-10 col-md-6 static">
                                        <div className="owl-slide-text">
                                            <h3 className="owl-slide-animated owl-slide-title">
                                                DISCOVER THE JOY OF INNOVATIVE LEARNING
                                                <br />
                                                A CURRICULUM
                                            </h3>
                                            <h2 className="owl-slide-animated owl-slide-subtitle">
                                                THAT ENCOURAGES THEM TO CREATE
                                            </h2>
                                            <div className="owl-slide-animated owl-slide-cta">
                                                <NavLink
                                                    className="btn btn-default me-3"
                                                    to="/donate-us"
                                                    role="button"
                                                >
                                                    Make a Difference
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="owl-slide d-flex align-items-center cover"
                            style={{
                                backgroundImage: "url(assets/images/banner5.webp)"
                            }}
                        >
                            <div className="container">
                                <div className="row justify-content-center justify-content-md-start no-gutters">
                                    <div className="col-10 col-md-6 static">
                                        <div className="owl-slide-text">
                                            <h3 className="owl-slide-animated owl-slide-title">
                                                The Place where innocence cheers with
                                            </h3>
                                            <h2 className="owl-slide-animated owl-slide-subtitle">
                                                JOY & BLOOM with FRESHNESS
                                            </h2>
                                            <div className="owl-slide-animated owl-slide-cta">
                                                <NavLink
                                                    className="btn btn-default me-3"
                                                    to="/donate-us"
                                                    role="button"
                                                >
                                                    Make a Difference
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="owl-slide d-flex align-items-center cover"
                            style={{
                                backgroundImage: "url(assets/images/banner6.webp)"
                            }}
                        >
                            <div className="container">
                                <div className="row justify-content-center justify-content-md-start no-gutters">
                                    <div className="col-10 col-md-6 static">
                                        <div className="owl-slide-text">
                                            <h3 className="owl-slide-animated owl-slide-title">
                                                Syndicate on the HOLY AIM <br />
                                                Syndicate that WORK TOGATHER
                                            </h3>
                                            <h2 className="owl-slide-animated owl-slide-subtitle">
                                                will bring out BEST TOGATHER
                                            </h2>
                                            <div className="owl-slide-animated owl-slide-cta">
                                                <NavLink
                                                    className="btn btn-default me-3"
                                                    to="/donate-us"
                                                    role="button"
                                                >
                                                    Make a Difference
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="owl-slide d-flex align-items-center cover"
                            style={{
                                backgroundImage: "url(assets/images/banner2.webp)"
                            }}
                        >
                            <div className="container">
                                <div className="row justify-content-center justify-content-md-start no-gutters">
                                    <div className="col-10 col-md-6 static">
                                        <div className="owl-slide-text">
                                            <h2 className="owl-slide-animated owl-slide-subtitle">
                                                A little PROGRESS each day adds up to BIG RESULTS
                                            </h2>
                                            <div className="owl-slide-animated owl-slide-cta">
                                                <NavLink
                                                    className="btn btn-default me-3"
                                                    to="/donate-us"
                                                    role="button"
                                                >
                                                    Donate Now
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="owl-slide d-flex align-items-center cover"
                            style={{
                                backgroundImage: "url(assets/images/banner1.webp)"
                            }}
                        >
                            <div className="container">
                                <div className="row justify-content-center justify-content-md-start no-gutters">
                                    <div className="col-10 col-md-6 static">
                                        <div className="owl-slide-text">
                                            <h2 className="owl-slide-animated owl-slide-subtitle">
                                                LEARN TODAY And Have A BRIGHT FUTURE
                                            </h2>
                                            <div className="owl-slide-animated owl-slide-cta">
                                                <NavLink
                                                    className="btn btn-default me-3"
                                                    to="/donate-us"
                                                    role="button"
                                                >
                                                    Donate
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SliderHome
