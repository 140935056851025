import React from 'react'
import UpcomingEvents from '../Components/UpcomingEvents'
import SliderHome from '../Components/SliderHome'
import AboutHome from '../Components/AboutHome'
// import TeamMember from '../Components/TeamMember'
import NewsArticles from '../Components/NewsArticles'

function Home() {
  document.title = "Home - Roshni Education Trust"

  return (
    <div>
      <SliderHome />
      <AboutHome />
      <UpcomingEvents />
      {/* <TeamMember members={4} /> */}
      <NewsArticles/>
    </div>
  )
}

export default Home
