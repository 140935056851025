import axios from 'axios';
import React, { useEffect, useState } from 'react'
import PageTitle from '../Components/PageTitle'
import EventsComp from '../Components/EventsComp'
import EventWithPhotos from '../Components/EventWithPhotos'
import { Route, Routes } from 'react-router-dom';

function Events() {
  document.title = "Events - Roshni Education Trust"
  const server = process.env.REACT_APP_APIPATH;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fillData = async () => {
    try {
      const response = await axios.get(`${server}api/Client/Events`);
      const json = await response.data;
      setData(json);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fillData();
  }, []);

  function convertDateToIndianFormat(utcTimestamp) {
    const date = new Date(utcTimestamp);

    // Calculate the offset in minutes
    const offsetInMinutes = date.getTimezoneOffset();

    // Adjust for IST by subtracting the offset from the Date object
    const istDateTime = new Date(date.getTime() - offsetInMinutes * 60000);

    return istDateTime;
  }

  function replaceSpaceWithDash(input) {
    return input.toLowerCase().split(" ").join("-");
  }

  return (
    <div>
      <PageTitle title="Events" />
      <Routes>
        <Route exact path="/" element={<EventsComp server={server} data={data} isLoading={isLoading} replaceSpaceWithDash={replaceSpaceWithDash} convertDateToIndianFormat={convertDateToIndianFormat} />} />
        {data.map(event => {
          return (
            <Route exact path={replaceSpaceWithDash(event.eventName)} element={<EventWithPhotos id={event.eventId} />} />
          );
        })}
      </Routes>
    </div>
  )
}

export default Events
