import React, { useEffect } from 'react'
import PageTitle from '../Components/PageTitle'
// import TeamMember from '../Components/TeamMember'
import { useLocation } from 'react-router-dom';

function AboutUs() {
  document.title = "About Us - Roshni Education Trust"

  const location = useLocation();

  useEffect(() => {
    const elementId = location.hash.substring(1);
    var time = elementId === "committee-members" ? 300 : 100;
    setTimeout(() => {
      scrollToElement(elementId);
    }, time);
  }, [location]);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <div>
      <PageTitle title="About Us" />
      <section className="wide-tb-100 bg-white shadow">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img src="assets/images/children-img.jpg" style={{ borderRadius: "10px" }} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 ps-5">
              <h1 className="heading-main">
                <small>ROSHNI EDUCATION TRUST</small>
                Charity Efforts Are For Happiness
              </h1>
              <p style={{ fontSize: '20px' }} className='text-black'>
                Roshni Education Trust has come into force. It stands for the ultimate motive to serve Humanity in the best possible way.
              </p>
              <p style={{ fontSize: '17px' }}>
                Lack of meaningful & value-based education and thinking worldly knowledge nonessential, false beliefs, myths and there also seems certain apathy in discipline and upbringing.
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wide-tb-100 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <h1 className="heading-main">
                <small>ABOUT US</small>
                Roshni Education Trust
              </h1>
              <p style={{ fontSize: '15px' }}>
                -Roshni Education Trust is dedicated to the betterment of Muslim society. Everything we do here revolves around the basis of Islam. Roshni Education Trust has come into force. It stands for the ultimate motive to serve Humanity in the best possible way.
                <br />
                -In order to help the Muslim community, Some give charity in the form of money, food, books, scholarship and even shelter. But Roshni Education Trust is a step above all. It not only helps them by all above but trains them providing education and discipline that makes them self-employed, independent and of course a human being first!!
                <br />
              </p>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img src="assets/images/about.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id='committee-members'>
        <TeamMember />
      </section> */}
    </div>
  )
}

export default AboutUs
