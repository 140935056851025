import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';

function UpcomingEvents() {
    const server = process.env.REACT_APP_APIPATH;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fillData = async () => {
        try {
            const response = await axios.get(`${server}api/Client/UpcomingEvents`);
            const json = await response.data;
            setData(json.slice(0, json.length > 5 ? 5 : json.length));
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fillData();
    }, []);

    function convertDateToIndianFormat(utcTimestamp) {
        const date = new Date(utcTimestamp);

        // Calculate the offset in minutes
        const offsetInMinutes = date.getTimezoneOffset();

        // Adjust for IST by subtracting the offset from the Date object
        const istDateTime = new Date(date.getTime() - offsetInMinutes * 60000);

        return istDateTime;
    }

    function replaceSpaceWithDash(input) {
        return input.toLowerCase().split(" ").join("-");
    }

    useEffect(() => {
        if (data.length > 0 && !isLoading) {
            window.jQuery("#home-second-events").owlCarousel({
                items: 1,
                margin: 30,
                loop: true,
                nav: true,
                slideBy: 1,
                dots: false,
                center: false,
                autoplay: true,
                autoheight: true,
                navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>'],
                responsive: {
                    320: {
                        items: 1,
                    },
                    600: {
                        items: 1,
                    },
                    767: {
                        items: 2,
                    },
                    1000: {
                        items: 3,
                        loop: true,
                    }
                }
            });
        }
    }, [isLoading]);

    if (isLoading) {
        return (
            <section className="wide-tb-30 bg-white">
                <div className="container">
                    <div className="row justify-content-between align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <h1 className="heading-main">
                                <Skeleton width={350} />
                            </h1>
                        </div>
                    </div>
                    <div className="row" >
                        {
                            Array.from({ length: 3 }, () => {
                                return (
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="item">
                                            <Skeleton height={400} width={300} />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="wide-tb-30 bg-white">
            <div className="container">
                <div className="row justify-content-between align-items-end">
                    <div className="col-lg-6 col-md-8">
                        <h1 className="heading-main">
                            Reach Out &amp; Help In Our Latest Events
                        </h1>
                    </div>
                </div>
                <div className="owl-carousel owl-theme" id="home-second-events">
                    {data.map(item => {
                        var date = new moment(convertDateToIndianFormat(item.eventDate));
                        return (
                            <div className="item" key={item.rowNumber}>
                                <div className="event-wrap-alternate">
                                    <div className="img-wrap">
                                        <div className="date-box">
                                            {date.date()} <span>{date.format("MMM")}</span>
                                        </div>
                                        <NavLink to={`/events/${replaceSpaceWithDash(item.eventName)}`}>
                                            <img
                                                src={server + item.photo} alt={item.eventName}
                                            />
                                        </NavLink>
                                        <div className="content-wrap">
                                            <h3>
                                                <NavLink to={`/events/${replaceSpaceWithDash(item.eventName)}`}>
                                                    {item.eventName}
                                                </NavLink>
                                            </h3>
                                            <div className="event-details">
                                                <div>
                                                    <i data-feather="clock" /> {date.format("dddd")}, {date.format('h:mm a')}
                                                </div>
                                                <div>
                                                    <i data-feather="map-pin" /> At {item.causeName}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                    })}
                </div>
                <div className="text-center mt-5">
                    <NavLink to="/events" className="btn btn-outline-dark">
                        View All Events
                    </NavLink>
                </div>
            </div>
        </section>



    )
}

export default UpcomingEvents

// <section className="latest-events-section">
//     <div className="outer-container">
//         <div className="lined-title">
//             <h2>Upcoming Events</h2>
//         </div>
//         <div className="row clearfix">
//             <Masonry
//                 breakpointCols={breakpointColumnsObj}
//                 className="my-masonry-grid"
//                 columnClassName="my-masonry-grid_column"
//             >
//                 {data.map(item => {
//                     var date = new moment(item.eventDate);
//                     return (
//                         <div className="event-post post-style-one col-sm-12 col-md col-lg p-5 my-masonry-grid_item" key={item.rowNumber}>
//                             <div className="inner-box clearfix">
//                                 <div className={`post-date ${hoveredItems[item.rowNumber] ? 'reduced-opacity' : ''}`}>
//                                     <span className="day">{date.date()}</span>
//                                     <span className="month">{date.format("MMM")}</span>
//                                 </div>
//                                 <figure
//                                     className="image-box"
//                                     onMouseOver={() => handleImageBoxHover(item.rowNumber)}
//                                     onMouseLeave={() => handleImageBoxLeave(item.rowNumber)}
//                                 >
//                                     <img src={server + item.photo} alt={item.eventName} />
//                                 </figure>
//                                 <div className="content-box">
//                                     <h3>
//                                         <a style={{ fontSize: "26px" }}>{item.eventName}</a>
//                                         <span className="line" />
//                                     </h3>
//                                     <ul className="info" style={{ fontSize: "20px" }}>
//                                         <li>
//                                             <span className="icon flaticon-time" /> {date.format("dddd")}, {date.format('h:mm a')}
//                                         </li>
//                                         <li>
//                                             <span className="icon flaticon-tool-2" /> At {item.causeName}
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     )
//                 })}
//             </Masonry>
//         </div>
//             <NavLink to="/events" className="theme-btn btn-style-two light">
//                 More
//                 <span className="sep-icon">
//                     <i className="fa fa-angle-right" />
//                 </span>
//             </NavLink>
//         </div>
// </section>