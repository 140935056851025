import React, { useEffect, useState } from "react";
import PageTitle from "../Components/PageTitle";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";

function Gallery() {
  const server = process.env.REACT_APP_APIPATH;
  document.title = "Image Gallery - Roshni Education Trust";
  const pageSize = 9;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fillData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${server}api/Client/ImageGallery?page=${page}&pagesize=${pageSize}`
      );
      const json = response.data;

      // Concatenate new data with existing data
      setData((prevData) => [...prevData, ...json.data]);

      // Update the hasMore state to determine if more data is available
      setHasMore(json.pagination.nextPage !== null);

      // Increment the page number for the next request if there's a nextPage
      if (json.pagination.nextPage !== null) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error(error);
      setHasMore(false); // Stop further requests if an error occurs
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fillData();
  }, []);

  useEffect(() => {
    // Initialize Magnific Popup
    const $ = window.$;
    if ($) {
      $(".img-gallery").magnificPopup({
        delegate: "a",
        type: "image",
        gallery: {
          enabled: true,
          preload: [0, 2],
          navigateByImgClick: true,
          arrowMarkup:
            '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
          tPrev: "Previous (Left arrow key)",
          tNext: "Next (Right arrow key)",
          tCounter: '<span class="mfp-counter">%curr% of %total%</span>',
        },
      });
    }
  }, [data]); // Reinitialize Magnific Popup whenever data changes

  function fetchMore() {
    if (!isLoading) {
      fillData();
    }
  }

  return (
    <div>
      <PageTitle title="Image Gallery" />
      <div
        id="scrollableDiv"
        style={{
          overflow: "auto",
          marginTop: "50px",
        }}
        className="row img-gallery g-0"
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry gutter="10px">
              {data.map((item) => (
                <a href={`${server}${item.photo}`} key={item.rowNumber}>
                  <img
                    alt={"image_" + item.rowNumber}
                    src={`${server}${item.photo}`}
                    className="img-gallery"
                  />
                </a>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default Gallery;
