import React from 'react'
import PageTitle from '../Components/PageTitle'

function OurVision() {
  return (
    <div>
      <PageTitle title="Our Vision & Mission" />
      <section id='our-vision' className="wide-tb-100 bg-white mb-spacer-md">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <h1 className="heading-main">
                <small>Our Vision</small>
              </h1>
              <div style={styles.image_container}>
                <img src="assets/images/about_home.jpg" style={{ borderRadius: "10px" }} alt="" />
              </div>
            </div>
            <div className="w-100 d-none d-sm-block d-lg-none spacer-60" />
            <div className="col-lg-7 col-md-12">
              <div className="ps-5">
                <h1 className="heading-main">
                  <br />
                  Make Someone’s Life By Giving of Yours.
                </h1>
                <p style={{ fontSize: '20px' }} className='text-black'>
                  Roshni Education Trust is dedicated to the betterment of Muslim society.
                </p>
                <div className="row">
                  <div className="col-12">
                    <p style={{ fontSize: '20px' }} className='text-black'>
                      Child Education
                    </p>
                    <p style={{ fontSize: '17px' }}>
                      To fulfil every parents need to provide their children with the right education and desired results.
                    </p>
                  </div>
                  <div className="col-12 mt-3">
                    <p style={{ fontSize: '20px' }} className='text-black'>
                      Islam knowledge
                    </p>
                    <p style={{ fontSize: '17px' }}>
                      To develop generations of proud and committed Muslims who will lead our community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-5">
                <ul style={{fontSize:"19px"}} className='custom-bullets'>
                  <li>
                  The primary vision of the Roshni Education Trust is to work for the
                  educational, social, and economic advancement of the Muslim community.
                  </li>
                  <li>
                  To Build and maintain a learning community that produces leaders through
                  faith, knowledge, and inspiration.
                  </li>
                  <li>
                  To provide value-based education that instils honesty, integrity, compassion,
                  and respectintoallourpupils.
                  </li>
                  <li>
                  To provide a strong core of tenacity, self-respect and self-belief that inspires
                  each pupil to become the best person they can be.
                  </li>
                  <li>
                  We are visionary, to promote Professional education in the Muslim
                  community by giving top prioritytoeducation in its objectives.
                  </li>
                  <li>
                  Educating and Empowering Muslims to meet up their demands and stand
                  strong on worldly platforms.
                  </li>
                  <li>
                  To fulfil every parents need to provide their children with the right education
                  and desired results.
                  </li>
                </ul>
            </div>
            <div className="col-lg-7 col-md-12 mt-5">
              <div className="ps-5">
                <h1 className="heading-main">
                  <small>Our Mission</small>
                  Everything we do here around the basis of Islam.
                </h1>
                <p style={{ fontSize: '20px' }} className='text-black'>
                  To enrich the public’s understanding of Islam and dispel common myths and stereotypes.
                </p>
                <p style={{ fontSize: '17px' }}>
                  Our holistic education aims to provide the environment for the total and balanced development of every associated individual in every sphere of learning. Spiritual, moral, imaginative, intellectual, cultural, aesthetic, emotional, and physical.
                  <br />
                </p>
              </div>
            </div>
            <div className="w-100 d-none d-sm-block d-lg-none spacer-60" />
            <div className="col-lg-5 col-md-12">
              <h1 className="heading-main">
                <br />
              </h1>
              <div style={styles.image_container}>
                <img src="assets/images/about_2.jpg" style={{ borderRadius: "10px" }} alt="" />
              </div>
            </div>
            <div className="col-md-12 mt-5">
                <ul style={{fontSize:"19px"}} className='custom-bullets'>
                  <li>
                   There is No Service Greater than Serving Humanity" so our mission is to
serve humanity.
                  </li>
                  <li>
                  Roshni Education Trust is dedicated to the betterment of Muslim society.
                  </li>
                  <li>
                  Our holistic education aims to provide the environment for the total and
balanced development of every associated individual in every sphere of
learning - spiritual, moral, imaginative, intellectual, cultural, aesthetic,
emotional, and physical.
                  </li>
                  <li>
                  Everything we do here revolves around the basis of Islam.
                  </li>
                  <li>
                  To activate Roshni Education Trust across the Globe, to make the world a
                  better and fairer place for every Muslim.
                  </li>
                  <li>
                  To enrich the public's understanding of Islam and dispel common myths and
stereotypes.
                  </li>
                  <li>
                  To develop generations of proud and committed Muslims who will lead our
community.
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const styles = {
  image_container: {
    position: "relative",
    display: "inline-block"
  },
  popup_video: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px"
  },
  button_i: {
    fontSize: "3rem",
    margin: "0"
  }
}

export default OurVision
