import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Toast, { showToast } from './Toast';
import axios from 'axios';

function DonationDetails() {
    const server = process.env.REACT_APP_APIPATH;
    const redirectURL = window.location.origin;
    const [PaymentTypes, setPaymentTypes] = useState([]);

    useEffect(() => {
        getPaymentTypes();
        setTimeout(() => {
            scrollToElement("donation-wrap");
        }, 100);
    }, []);

    const getPaymentTypes = async () => {
        try {
          const response = await axios.get(`${server}api/Payment/PaymentTypes`);
          const json = await response.data;
          setPaymentTypes(json);
        } catch (error) {
          console.error(error);
        }
      };

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const amount = useSelector(state => state.paymentDetails.amount);
    const form = useRef();
    const [details, setDetails] = useState({
        firstName: "",
        lastName: "",
        mobileNo: "",
        emailID: "",
        pan: "",
        txnid: "",
        hash: "",
        paymentTypeID:0
    });
    const [IsLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (details.txnid && details.hash) {
            form.current.submit();
        }
    }, [details.txnid, details.hash]);

    function sha512(str) {
        return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
            return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
        });
    }

    const navigate = useNavigate();

    useEffect(() => {
        if (amount == "" || amount <= 0) {
            navigate('/donate-us');
        }
    }, []);


    async function submitDetails() {
        try {
            if (details.firstName === "") {
                showToast("Please enter your first name", "error", 2000);
                return;
            } else if (details.lastName === "") {
                showToast("Please enter your last name", "error", 2000);
                return;
            } else if (details.emailID === "") {
                showToast("Please enter your Email ID", "error", 2000);
                return;
            } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(details.emailID)) {
                showToast("Please enter a valid Email ID", "error", 2000);
                return;
            } else if (details.mobileNo === "") {
                showToast("Please enter your mobile number", "error", 2000);
                return;
            } else if (!/^[0-9]{10}$/.test(details.mobileNo)) {
                showToast("Please enter a valid 10 digit mobile number", "error", 2000);
                return;
            } else if (details.pan != "" && !/^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(details.pan)) {
                showToast("Please enter a valid PAN number", "error", 2000);
                return;
            }else if (details.paymentTypeID == "0") {
                showToast("Please Select Donation Type", "error", 2000);
                return;
            }

            // var paymentInitiateDetails = {
            //     firstName: details.firstName,
            //     lastName: details.lastName,
            //     emailid: details.emailID,
            //     mobileno: details.mobileNo,
            //     pan: details.pan,
            //     amount: amount,
            //     paymentTypeID:details.paymentTypeID
            // };
            // setIsLoading(prev => true);
            // const response = await axios.post(server + "api/Payment/InitiatePayment", paymentInitiateDetails);
            // const txnid = await response.data;
            // setDetails(prev => { return { ...prev, txnid } });

            // const hashString = `gtKFFx|${txnid}|${amount}|donation|${details.firstName}|${details.emailID}|${redirectURL}||||||||||4R38IvwiV57FwVpsgOvTXBdLE4tHUXFW`;
            // sha512(hashString).then((result) => {
            //     setDetails(prev => { return { ...prev, hash: result } });
            // }).catch((err) => {
            //     console.error(err);
            //     setIsLoading(prev => false);
            // });
        } catch (err) {
            console.error(err);
            setIsLoading(prev => false);
        }
    }

    return (
        <div id="donation-wrap">
            <Toast />
            <div className="donation-wrap mt-1" >
                {/* <form action='https://test.payu.in/_payment' method='post' ref={form}>
                    <input type="hidden" name="key" value="gtKFFx" />
                    <input type="hidden" name="txnid" value={details.txnid} />
                    <input type="hidden" name="productinfo" value="donation" />
                    <input type="hidden" name="amount" value={amount} />
                    <input type="hidden" name="email" value={details.emailID} />
                    <input type="hidden" name="firstname" value={details.firstName} />
                    <input type="hidden" name="lastname" value={details.lastName} />
                    <input type="hidden" name="udf1" value={redirectURL} />
                    <input type="hidden" name="surl" value={server + "api/Payment/success"} />
                    <input type="hidden" name="furl" value={server + "api/Payment/failure"} />
                    <input type="hidden" name="phone" value={details.mobileNo} />
                    <input type="hidden" name="hash" value={details.hash} />
                    <input type="submit" style={{ visibility: "hidden" }} value="submit" />
                </form> */}
                <h3 className="h3-sm fw-5 txt-blue mb-3">
                    Please Enter Your Details
                </h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="txtFirstName"
                                placeholder="First Name"
                                value={details.firstName}
                                onChange={e => setDetails({ ...details, firstName: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="txtLastName"
                                placeholder="Last Name"
                                value={details.lastName}
                                onChange={e => setDetails({ ...details, lastName: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input
                                type="emailID"
                                className="form-control"
                                id="txtEmail"
                                placeholder="Your Email"
                                value={details.emailID}
                                onChange={e => setDetails({ ...details, emailID: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="txtMobileNo"
                                placeholder="Mobile No"
                                maxLength={10}
                                value={details.mobileNo}
                                onChange={e => setDetails({ ...details, mobileNo: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="txtPan"
                                placeholder="PAN (Optional)"
                                value={details.pan}
                                onChange={e => setDetails({ ...details, pan: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <select
                                className="form-control"
                                id="ddlPaymentType"
                                onChange={e => setDetails({ ...details, paymentTypeID: e.target.value })}
                            >
                            <option value="0">-- Select Donation Type --</option>
                            {PaymentTypes.map(type=>{
                                return <option value={type.paymentTypeID}>{type.paymentType}</option>
                            })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="button" onClick={submitDetails} disabled className="btn btn-default" style={{ width: "100%", borderRadius: '5px', padding: "10px", paddingLeft: "20px", paddingRight: "20px", bottom: 0 }} >
                            {/* {IsLoading ? <div class="spinner-border  text-secondary">
                                <span class="visually-hidden">Loading...</span>
                            </div> : "PAY NOW"
                            } */}
                            Coming Soon ...
                        </button>
                    </div>
                    {/* <div className="col-md-12">
                        <p className="text-primary" style={{ fontSize: '15px' }}>
                            Please Enter your PAN to avail the 80G tax exemption certificate.
                        </p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default DonationDetails
