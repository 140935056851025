import React from 'react'
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Toast() {
    return (
        <ToastContainer />
    )
}

function showToast(message, type = "default", ms = 2500) {
    switch (type) {
        case "success":
            toast.success(message, {
                position: "top-right",
                autoClose: ms,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Zoom
            });
            break;
        case "warning":
            toast.warn(message, {
                position: "top-right",
                autoClose: ms,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Zoom
            });
            break;
        case "info":
            toast.info(message, {
                position: "top-right",
                autoClose: ms,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Zoom
            });
            break;
        case "error":
            toast.error(message, {
                position: "top-right",
                autoClose: ms,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Zoom
            });
            break;
        case "default":
        case "":
            toast(message, {
                position: "top-right",
                autoClose: ms,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Zoom
            });
            break;
        default:
            break;
    }

}

export default Toast

export { showToast };
