import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment';

function EventsComp({ server, isLoading, data, replaceSpaceWithDash, convertDateToIndianFormat }) {

    if (isLoading) {
        return (
            <section className="wide-tb-100">
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-sm-1">
                        {
                            Array.from({ length: 6 }, () => {
                                return (
                                    <div className="col mb-5">
                                        <Skeleton height={390} />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </section>
        );
    }

    return (
        <div>
            <section className="wide-tb-100">
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-sm-1">
                        {data.map(item => {
                            var date = new moment(convertDateToIndianFormat(item.eventDate));
                            return (
                                <div className="col mb-5">
                                    <div className="event-wrap-alternate">
                                        <NavLink to={replaceSpaceWithDash(item.eventName)}>
                                            <div className="img-wrap">
                                                <div className="date-box">
                                                    {date.date()} <span>{date.format("MMM")}</span>
                                                </div>
                                                <img
                                                    src={server + item.photo} alt={item.eventName}
                                                />
                                                <div className="content-wrap">
                                                    <h3>
                                                        <NavLink to={replaceSpaceWithDash(item.eventName)}>
                                                            {item.eventName}
                                                        </NavLink>
                                                    </h3>
                                                    <div className="event-details">
                                                        <div>
                                                            <i data-feather="clock" /> {date.format("dddd")}, {date.format('h:mm a')}
                                                        </div>
                                                        <div>
                                                            <i data-feather="map-pin" /> At {item.causeName}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>)
                        })
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EventsComp
