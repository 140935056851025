import React from 'react'

function AboutHome() {
    return (
        <div>
            <section className="wide-tb-30 bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img src="assets/images/about_home.webp" style={{maxHeight:"430px"}}  alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-md-3">
                            <h1 className="heading-main">
                                <small>About Us</small>
                                Roshni Education Trust
                            </h1>
                            <p style={{fontSize: '15px'}}>
                                -Roshni Education Trust is dedicated to the betterment of Muslim society. Everything we do here revolves around the basis of Islam. Roshni Education Trust has come into force. It stands for the ultimate motive to serve Humanity in the best possible way.
                                <br />
                                -In order to help the Muslim community, Some give charity in the form of money, food, books, scholarship and even shelter. But Roshni Education Trust is a step above all. It not only helps them by all above but trains them providing education and discipline that makes them self-employed, independent and of course a human being first!!
                                <br />
                                {/* -Lack of meaningful & value-based education and thinking worldly knowledge nonessential, false beliefs, myths and there also seems certain apathy in discipline and upbringing. */}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default AboutHome
