import React from 'react'
import { NavLink } from 'react-router-dom'

function Success() {
  return (
    <div>
      <main id="body-content" className="w-100 error-style">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <h1 className="heading-main">
                <small>Payment Success !</small>
                Thank You !
              </h1>
              <p></p>
              <NavLink className="btn btn-primary mt-4" to="/">
                Back to home
              </NavLink>
            </div>
            <div className="col-lg-9">
              <div className="text-center error-img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1920 1080"
                  width={1920}
                  height={1080}
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    width: "100%",
                    height: "100%",
                    transform: "translate3d(0px, 0px, 0px)",
                    contentVisibility: "visible"
                  }}
                >
                  <defs>
                    <clipPath id="__lottie_element_2">
                      <rect width={1920} height={1080} x={0} y={0} />
                    </clipPath>
                  </defs>
                  <g clipPath="url(#__lottie_element_2)">
                    <g
                      style={{ display: "none" }}
                      transform="matrix(7.844322681427002,0,0,7.844322681427002,960,540)"
                      opacity="0.003611893904356549"
                    >
                      <g opacity={1} transform="matrix(1,0,0,1,45.25,-60.75)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M0,-7.75 C4.277225017547607,-7.75 7.75,-4.277225017547607 7.75,0 C7.75,4.277225017547607 4.277225017547607,7.75 0,7.75 C-4.277225017547607,7.75 -7.75,4.277225017547607 -7.75,0 C-7.75,-4.277225017547607 -4.277225017547607,-7.75 0,-7.75z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-46.75,-50.25)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M0,-6.25 C3.4493749141693115,-6.25 6.25,-3.4493749141693115 6.25,0 C6.25,3.4493749141693115 3.4493749141693115,6.25 0,6.25 C-3.4493749141693115,6.25 -6.25,3.4493749141693115 -6.25,0 C-6.25,-3.4493749141693115 -3.4493749141693115,-6.25 0,-6.25z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-50.25,41.25)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M0,-7.25 C4.001275062561035,-7.25 7.25,-4.001275062561035 7.25,0 C7.25,4.001275062561035 4.001275062561035,7.25 0,7.25 C-4.001275062561035,7.25 -7.25,4.001275062561035 -7.25,0 C-7.25,-4.001275062561035 -4.001275062561035,-7.25 0,-7.25z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,54.25,32.75)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M0,-5.75 C3.1734249591827393,-5.75 5.75,-3.1734249591827393 5.75,0 C5.75,3.1734249591827393 3.1734249591827393,5.75 0,5.75 C-3.1734249591827393,5.75 -5.75,3.1734249591827393 -5.75,0 C-5.75,-3.1734249591827393 -3.1734249591827393,-5.75 0,-5.75z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-56.5,9.5)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M0,-3 C1.6556999683380127,-3 3,-1.6556999683380127 3,0 C3,1.6556999683380127 1.6556999683380127,3 0,3 C-1.6556999683380127,3 -3,1.6556999683380127 -3,0 C-3,-1.6556999683380127 -1.6556999683380127,-3 0,-3z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,55,-14)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M0,-3.5 C1.9316500425338745,-3.5 3.5,-1.9316500425338745 3.5,0 C3.5,1.9316500425338745 1.9316500425338745,3.5 0,3.5 C-1.9316500425338745,3.5 -3.5,1.9316500425338745 -3.5,0 C-3.5,-1.9316500425338745 -1.9316500425338745,-3.5 0,-3.5z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-4.25,-61.75)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M0,-3.25 C1.7936749458312988,-3.25 3.25,-1.7936749458312988 3.25,0 C3.25,1.7936749458312988 1.7936749458312988,3.25 0,3.25 C-1.7936749458312988,3.25 -3.25,1.7936749458312988 -3.25,0 C-3.25,-1.7936749458312988 -1.7936749458312988,-3.25 0,-3.25z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-0.25,58.75)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M0,-3.75 C2.069624900817871,-3.75 3.75,-2.069624900817871 3.75,0 C3.75,2.069624900817871 2.069624900817871,3.75 0,3.75 C-2.069624900817871,3.75 -3.75,2.069624900817871 -3.75,0 C-3.75,-2.069624900817871 -2.069624900817871,-3.75 0,-3.75z"
                        />
                      </g>
                    </g>
                    <g
                      style={{ display: "none" }}
                      transform="matrix(5.545302867889404,0,0,5.545302867889404,960,540)"
                      opacity="0.003611893904356549"
                    >
                      <g opacity={1} transform="matrix(1,0,0,1,89.5,-14.5)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M4.457714172833097e-16,-7.28000020980835 C4.457714172833097e-16,-7.28000020980835 2.139538288116455,-2.944821834564209 2.139538288116455,-2.944821834564209 C2.139538288116455,-2.944821834564209 6.923691272735596,-2.2496438026428223 6.923691272735596,-2.2496438026428223 C6.923691272735596,-2.2496438026428223 3.461845636367798,1.1248219013214111 3.461845636367798,1.1248219013214111 C3.461845636367798,1.1248219013214111 4.27907657623291,5.889643669128418 4.27907657623291,5.889643669128418 C4.27907657623291,5.889643669128418 2.2288570864165483e-16,3.640000104904175 2.2288570864165483e-16,3.640000104904175 C2.2288570864165483e-16,3.640000104904175 -4.27907657623291,5.889643669128418 -4.27907657623291,5.889643669128418 C-4.27907657623291,5.889643669128418 -3.461845636367798,1.1248219013214111 -3.461845636367798,1.1248219013214111 C-3.461845636367798,1.1248219013214111 -6.923691272735596,-2.2496438026428223 -6.923691272735596,-2.2496438026428223 C-6.923691272735596,-2.2496438026428223 -2.139538288116455,-2.944821834564209 -2.139538288116455,-2.944821834564209 C-2.139538288116455,-2.944821834564209 4.457714172833097e-16,-7.28000020980835 4.457714172833097e-16,-7.28000020980835z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-82,-12)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M5.267206090469494e-16,-8.60200023651123 C5.267206090469494e-16,-8.60200023651123 2.528064250946045,-3.4795820713043213 2.528064250946045,-3.4795820713043213 C2.528064250946045,-3.4795820713043213 8.180988311767578,-2.6581642627716064 8.180988311767578,-2.6581642627716064 C8.180988311767578,-2.6581642627716064 4.090494155883789,1.3290821313858032 4.090494155883789,1.3290821313858032 C4.090494155883789,1.3290821313858032 5.05612850189209,6.959164142608643 5.05612850189209,6.959164142608643 C5.05612850189209,6.959164142608643 2.633603045234747e-16,4.301000118255615 2.633603045234747e-16,4.301000118255615 C2.633603045234747e-16,4.301000118255615 -5.05612850189209,6.959164142608643 -5.05612850189209,6.959164142608643 C-5.05612850189209,6.959164142608643 -4.090494155883789,1.3290821313858032 -4.090494155883789,1.3290821313858032 C-4.090494155883789,1.3290821313858032 -8.180988311767578,-2.6581642627716064 -8.180988311767578,-2.6581642627716064 C-8.180988311767578,-2.6581642627716064 -2.528064250946045,-3.4795820713043213 -2.528064250946045,-3.4795820713043213 C-2.528064250946045,-3.4795820713043213 5.267206090469494e-16,-8.60200023651123 5.267206090469494e-16,-8.60200023651123z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-3.5,-91.75)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M3.3818621570207434e-16,-5.5229997634887695 C3.3818621570207434e-16,-5.5229997634887695 1.6228750944137573,-2.2336959838867188 1.6228750944137573,-2.2336959838867188 C1.6228750944137573,-2.2336959838867188 5.252685070037842,-1.7067008018493652 5.252685070037842,-1.7067008018493652 C5.252685070037842,-1.7067008018493652 2.6258671283721924,0.8531959056854248 2.6258671283721924,0.8531959056854248 C2.6258671283721924,0.8531959056854248 3.246337890625,4.46820068359375 3.246337890625,4.46820068359375 C3.246337890625,4.46820068359375 1.690624955509278e-16,2.760999917984009 1.690624955509278e-16,2.760999917984009 C1.690624955509278e-16,2.760999917984009 -3.246337890625,4.46820068359375 -3.246337890625,4.46820068359375 C-3.246337890625,4.46820068359375 -2.6258671283721924,0.8531959056854248 -2.6258671283721924,0.8531959056854248 C-2.6258671283721924,0.8531959056854248 -5.252685070037842,-1.7067008018493652 -5.252685070037842,-1.7067008018493652 C-5.252685070037842,-1.7067008018493652 -1.6228750944137573,-2.2336959838867188 -1.6228750944137573,-2.2336959838867188 C-1.6228750944137573,-2.2336959838867188 3.3818621570207434e-16,-5.5229997634887695 3.3818621570207434e-16,-5.5229997634887695z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,2.5,81)">
                        <path
                          fill="rgb(3,255,152)"
                          fillOpacity={1}
                          d=" M5.267206090469494e-16,-8.60200023651123 C5.267206090469494e-16,-8.60200023651123 2.528064250946045,-3.4795820713043213 2.528064250946045,-3.4795820713043213 C2.528064250946045,-3.4795820713043213 8.180988311767578,-2.6581642627716064 8.180988311767578,-2.6581642627716064 C8.180988311767578,-2.6581642627716064 4.090494155883789,1.3290821313858032 4.090494155883789,1.3290821313858032 C4.090494155883789,1.3290821313858032 5.05612850189209,6.959164142608643 5.05612850189209,6.959164142608643 C5.05612850189209,6.959164142608643 2.633603045234747e-16,4.301000118255615 2.633603045234747e-16,4.301000118255615 C2.633603045234747e-16,4.301000118255615 -5.05612850189209,6.959164142608643 -5.05612850189209,6.959164142608643 C-5.05612850189209,6.959164142608643 -4.090494155883789,1.3290821313858032 -4.090494155883789,1.3290821313858032 C-4.090494155883789,1.3290821313858032 -8.180988311767578,-2.6581642627716064 -8.180988311767578,-2.6581642627716064 C-8.180988311767578,-2.6581642627716064 -2.528064250946045,-3.4795820713043213 -2.528064250946045,-3.4795820713043213 C-2.528064250946045,-3.4795820713043213 5.267206090469494e-16,-8.60200023651123 5.267206090469494e-16,-8.60200023651123z"
                        />
                      </g>
                    </g>
                    <g
                      style={{ display: "none" }}
                      transform="matrix(5.336141586303711,0,0,5.336141586303711,1284.170654296875,559.5126342773438)"
                      opacity="0.0023295696101747866"
                    >
                      <g opacity={1} transform="matrix(1,0,0,1,-60.75,-4.25)">
                        <path
                          fill="rgb(127,255,202)"
                          fillOpacity={1}
                          d=" M0,-61.25 C33.80387496948242,-61.25 61.25,-33.80387496948242 61.25,0 C61.25,33.80387496948242 33.80387496948242,61.25 0,61.25 C-33.80387496948242,61.25 -61.25,33.80387496948242 -61.25,0 C-61.25,-33.80387496948242 -33.80387496948242,-61.25 0,-61.25z"
                        />
                      </g>
                    </g>
                    <g
                      style={{ display: "block" }}
                      transform="matrix(5.087500095367432,0,0,5.087500095367432,1275.425048828125,558.3937377929688)"
                      opacity={1}
                    >
                      <g opacity={1} transform="matrix(1,0,0,1,-62,-4.5)">
                        <path
                          fill="#3e6e5a"
                          fillOpacity={1}
                          d=" M0,-40 C22.076000213623047,-40 40,-22.076000213623047 40,0 C40,22.076000213623047 22.076000213623047,40 0,40 C-22.076000213623047,40 -40,22.076000213623047 -40,0 C-40,-22.076000213623047 -22.076000213623047,-40 0,-40z"
                        />
                      </g>
                    </g>
                    <g
                      transform="matrix(3.763040065765381,0,0,3.763040065765381,961.1409912109375,540)"
                      opacity={1}
                      style={{ display: "block" }}
                    >
                      <g opacity={1} transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="miter"
                          fillOpacity={0}
                          strokeMiterlimit={4}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth={12}
                          d=" M-23,-3.5 C-23,-3.5 -7.5,12 -7.5,12 C-7.5,12 20.5,-16 20.5,-16"
                        />
                      </g>
                    </g>
                    <g
                      fill="#3e6e5a"
                      fontSize={64}
                      fontFamily="SF UI Display"
                      fontStyle="normal"
                      fontWeight={700}
                      aria-label="Payment Successful"
                      transform="matrix(1,0,0,1,664.6409912109375,864)"
                      opacity={1}
                      style={{ display: "block" }}
                    >
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,0,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M13.19,-37.62 C13.19,-37.62 20.06,-37.62 20.06,-37.62 C25.47,-37.62 28.59,-34.75 28.59,-29.62 C28.59,-24.47 25.44,-21.59 20.03,-21.59 C20.03,-21.59 13.19,-21.59 13.19,-21.59 C13.19,-21.59 13.19,-37.62 13.19,-37.62z M3.75,-45.09 C3.75,-45.09 3.75,0 3.75,0 C3.75,0 13.19,0 13.19,0 C13.19,0 13.19,-14.22 13.19,-14.22 C13.19,-14.22 22.09,-14.22 22.09,-14.22 C31.59,-14.22 38.19,-20.41 38.19,-29.66 C38.19,-38.94 31.81,-45.09 22.56,-45.09 C22.56,-45.09 3.75,-45.09 3.75,-45.09z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,40,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M13.28,0.41 C17.69,0.41 21.94,-1.72 23.81,-5.31 C23.81,-5.31 24,-5.31 24,-5.31 C24,-5.31 24,0 24,0 C24,0 32.81,0 32.81,0 C32.81,0 32.81,-23.59 32.81,-23.59 C32.81,-30.47 27.03,-35 18.19,-35 C9.59,-35 3.66,-30.47 3.41,-23.91 C3.41,-23.91 11.75,-23.91 11.75,-23.91 C12.19,-26.41 14.5,-28.16 17.81,-28.16 C21.56,-28.16 23.84,-26.34 23.84,-23.03 C23.84,-23.03 23.84,-20.75 23.84,-20.75 C23.84,-20.75 15.25,-20.22 15.25,-20.22 C6.47,-19.69 1.69,-16.16 1.69,-9.91 C1.69,-3.75 6.66,0.41 13.28,0.41z M16.25,-6.25 C12.94,-6.25 10.78,-7.84 10.78,-10.38 C10.78,-12.91 12.81,-14.41 16.63,-14.66 C16.63,-14.66 23.84,-15.16 23.84,-15.16 C23.84,-15.16 23.84,-12.63 23.84,-12.63 C23.84,-8.88 20.47,-6.25 16.25,-6.25z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,75.84639739990234,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M7.47,12.56 C16.06,12.56 20.28,9.47 23.22,0.69 C23.22,0.69 35.13,-34.41 35.13,-34.41 C35.13,-34.41 25.5,-34.41 25.5,-34.41 C25.5,-34.41 18.03,-7.41 18.03,-7.41 C18.03,-7.41 17.84,-7.41 17.84,-7.41 C17.84,-7.41 10.41,-34.41 10.41,-34.41 C10.41,-34.41 0.41,-34.41 0.41,-34.41 C0.41,-34.41 12.47,0.22 12.47,0.22 C12.47,0.22 12.16,1.53 12.16,1.53 C11.44,4.28 9.47,5.47 6.06,5.47 C5.69,5.47 4.31,5.44 4,5.37 C4,5.37 4,12.44 4,12.44 C4.34,12.5 7.13,12.56 7.47,12.56z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,111.81439971923828,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M3.09,0 C3.09,0 12.22,0 12.22,0 C12.22,0 12.22,-20.69 12.22,-20.69 C12.22,-24.59 14.72,-27.34 18.31,-27.34 C21.84,-27.34 23.97,-25.13 23.97,-21.44 C23.97,-21.44 23.97,0 23.97,0 C23.97,0 32.78,0 32.78,0 C32.78,0 32.78,-20.81 32.78,-20.81 C32.78,-24.72 35.19,-27.34 38.78,-27.34 C42.5,-27.34 44.53,-25.13 44.53,-21.09 C44.53,-21.09 44.53,0 44.53,0 C44.53,0 53.66,0 53.66,0 C53.66,0 53.66,-23.37 53.66,-23.37 C53.66,-30.31 49.13,-34.91 42.38,-34.91 C37.37,-34.91 33.25,-32.19 31.69,-27.88 C31.69,-27.88 31.5,-27.88 31.5,-27.88 C30.34,-32.38 26.84,-34.91 21.91,-34.91 C17.31,-34.91 13.56,-32.31 12.09,-28.16 C12.09,-28.16 11.91,-28.16 11.91,-28.16 C11.91,-28.16 11.91,-34.41 11.91,-34.41 C11.91,-34.41 3.09,-34.41 3.09,-34.41 C3.09,-34.41 3.09,0 3.09,0z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,168.4095916748047,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M25.88,-10.5 C25,-7.84 22.28,-6.22 18.84,-6.22 C14.03,-6.22 10.81,-9.56 10.81,-14.53 C10.81,-14.53 10.81,-15.09 10.81,-15.09 C10.81,-15.09 34.56,-15.09 34.56,-15.09 C34.56,-15.09 34.56,-17.91 34.56,-17.91 C34.56,-28.38 28.25,-35 18.34,-35 C8.25,-35 1.81,-28 1.81,-17.06 C1.81,-6.12 8.22,0.59 18.69,0.59 C27.06,0.59 33.28,-3.84 34.28,-10.5 C34.28,-10.5 25.88,-10.5 25.88,-10.5z M18.44,-28.19 C22.66,-28.19 25.59,-25.25 25.75,-20.75 C25.75,-20.75 10.91,-20.75 10.91,-20.75 C11.22,-25.16 14.28,-28.19 18.44,-28.19z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,204.78720092773438,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M3.09,0 C3.09,0 12.22,0 12.22,0 C12.22,0 12.22,-19.62 12.22,-19.62 C12.22,-24.19 14.84,-27.31 19.22,-27.31 C23.53,-27.31 25.66,-24.72 25.66,-20.16 C25.66,-20.16 25.66,0 25.66,0 C25.66,0 34.78,0 34.78,0 C34.78,0 34.78,-22.06 34.78,-22.06 C34.78,-30.09 30.47,-34.91 22.84,-34.91 C17.56,-34.91 14.06,-32.59 12.09,-28.25 C12.09,-28.25 11.91,-28.25 11.91,-28.25 C11.91,-28.25 11.91,-34.41 11.91,-34.41 C11.91,-34.41 3.09,-34.41 3.09,-34.41 C3.09,-34.41 3.09,0 3.09,0z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,242.50880432128906,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M5.53,-42.06 C5.53,-42.06 5.53,-34.41 5.53,-34.41 C5.53,-34.41 0.87,-34.41 0.87,-34.41 C0.87,-34.41 0.87,-27.5 0.87,-27.5 C0.87,-27.5 5.53,-27.5 5.53,-27.5 C5.53,-27.5 5.53,-9.28 5.53,-9.28 C5.53,-2.56 8.66,0.12 16.63,0.12 C18.47,0.12 20.09,-0.06 21.03,-0.25 C21.03,-0.25 21.03,-7 21.03,-7 C20.47,-6.91 19.56,-6.84 18.75,-6.84 C15.97,-6.84 14.66,-8.13 14.66,-10.78 C14.66,-10.78 14.66,-27.5 14.66,-27.5 C14.66,-27.5 21.03,-27.5 21.03,-27.5 C21.03,-27.5 21.03,-34.41 21.03,-34.41 C21.03,-34.41 14.66,-34.41 14.66,-34.41 C14.66,-34.41 14.66,-42.06 14.66,-42.06 C14.66,-42.06 5.53,-42.06 5.53,-42.06z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,265.66400146484375,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g style={{ display: "none" }} />
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,279.41119384765625,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M2.06,-12.84 C2.34,-4.38 9.25,0.78 20.09,0.78 C31.28,0.78 38.38,-4.75 38.38,-13.5 C38.38,-20.38 34.44,-24.25 25.41,-26.19 C25.41,-26.19 19.94,-27.34 19.94,-27.34 C14.75,-28.53 12.56,-30.09 12.56,-32.87 C12.56,-36.22 15.69,-38.44 20.34,-38.44 C25.06,-38.44 28.41,-36.12 28.72,-32.31 C28.72,-32.31 37.56,-32.31 37.56,-32.31 C37.41,-40.47 30.81,-45.88 20.31,-45.88 C10.25,-45.88 3.06,-40.38 3.06,-32.06 C3.06,-25.47 7.16,-21.25 15.47,-19.47 C15.47,-19.47 21.34,-18.19 21.34,-18.19 C26.78,-16.97 28.94,-15.34 28.94,-12.41 C28.94,-9.09 25.5,-6.69 20.47,-6.69 C15.47,-6.69 11.59,-9.12 11.16,-12.84 C11.16,-12.84 2.06,-12.84 2.06,-12.84z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,319.8144226074219,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M34.63,-34.41 C34.63,-34.41 25.5,-34.41 25.5,-34.41 C25.5,-34.41 25.5,-14.81 25.5,-14.81 C25.5,-10.16 22.84,-7.09 18.59,-7.09 C14.38,-7.09 12.06,-9.59 12.06,-14.25 C12.06,-14.25 12.06,-34.41 12.06,-34.41 C12.06,-34.41 2.94,-34.41 2.94,-34.41 C2.94,-34.41 2.94,-12.34 2.94,-12.34 C2.94,-4.34 7.84,0.5 15.28,0.5 C20.5,0.5 23.88,-1.91 25.62,-6.22 C25.62,-6.22 25.81,-6.22 25.81,-6.22 C25.81,-6.22 25.81,0 25.81,0 C25.81,0 34.63,0 34.63,0 C34.63,0 34.63,-34.41 34.63,-34.41z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,357.5360107421875,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M34.19,-21.72 C33.75,-29.38 27.84,-35 18.59,-35 C8.38,-35 1.81,-28.12 1.81,-17.22 C1.81,-6.16 8.38,0.59 18.66,0.59 C27.69,0.59 33.69,-4.5 34.22,-12.5 C34.22,-12.5 25.72,-12.5 25.72,-12.5 C25.13,-8.72 22.59,-6.63 18.75,-6.63 C14.03,-6.63 11.06,-10.5 11.06,-17.22 C11.06,-23.81 14.03,-27.81 18.72,-27.81 C22.69,-27.81 25.19,-25.28 25.69,-21.72 C25.69,-21.72 34.19,-21.72 34.19,-21.72z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,393.222412109375,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M34.19,-21.72 C33.75,-29.38 27.84,-35 18.59,-35 C8.38,-35 1.81,-28.12 1.81,-17.22 C1.81,-6.16 8.38,0.59 18.66,0.59 C27.69,0.59 33.69,-4.5 34.22,-12.5 C34.22,-12.5 25.72,-12.5 25.72,-12.5 C25.13,-8.72 22.59,-6.63 18.75,-6.63 C14.03,-6.63 11.06,-10.5 11.06,-17.22 C11.06,-23.81 14.03,-27.81 18.72,-27.81 C22.69,-27.81 25.19,-25.28 25.69,-21.72 C25.69,-21.72 34.19,-21.72 34.19,-21.72z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,428.9088134765625,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M25.88,-10.5 C25,-7.84 22.28,-6.22 18.84,-6.22 C14.03,-6.22 10.81,-9.56 10.81,-14.53 C10.81,-14.53 10.81,-15.09 10.81,-15.09 C10.81,-15.09 34.56,-15.09 34.56,-15.09 C34.56,-15.09 34.56,-17.91 34.56,-17.91 C34.56,-28.38 28.25,-35 18.34,-35 C8.25,-35 1.81,-28 1.81,-17.06 C1.81,-6.12 8.22,0.59 18.69,0.59 C27.06,0.59 33.28,-3.84 34.28,-10.5 C34.28,-10.5 25.88,-10.5 25.88,-10.5z M18.44,-28.19 C22.66,-28.19 25.59,-25.25 25.75,-20.75 C25.75,-20.75 10.91,-20.75 10.91,-20.75 C11.22,-25.16 14.28,-28.19 18.44,-28.19z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,465.286376953125,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M2.5,-24.28 C2.5,-19.25 5.84,-15.91 12.31,-14.44 C12.31,-14.44 18.66,-13 18.66,-13 C21.78,-12.25 22.97,-11.31 22.97,-9.53 C22.97,-7.28 20.75,-5.88 17.13,-5.88 C13.34,-5.88 11,-7.5 10.56,-10.41 C10.56,-10.41 1.66,-10.41 1.66,-10.41 C2.12,-3.78 7.81,0.59 16.87,0.59 C25.88,0.59 32.03,-3.81 32.03,-10.5 C32.03,-15.62 29.28,-18.28 22.38,-19.84 C22.38,-19.84 15.91,-21.25 15.91,-21.25 C12.81,-21.97 11.31,-23.12 11.31,-24.84 C11.31,-27.06 13.5,-28.53 16.78,-28.53 C20.28,-28.53 22.56,-26.81 22.75,-24.09 C22.75,-24.09 31.19,-24.09 31.19,-24.09 C31.06,-30.66 25.41,-35 16.91,-35 C8.19,-35 2.5,-30.75 2.5,-24.28z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,498.9759826660156,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M2.5,-24.28 C2.5,-19.25 5.84,-15.91 12.31,-14.44 C12.31,-14.44 18.66,-13 18.66,-13 C21.78,-12.25 22.97,-11.31 22.97,-9.53 C22.97,-7.28 20.75,-5.88 17.13,-5.88 C13.34,-5.88 11,-7.5 10.56,-10.41 C10.56,-10.41 1.66,-10.41 1.66,-10.41 C2.12,-3.78 7.81,0.59 16.87,0.59 C25.88,0.59 32.03,-3.81 32.03,-10.5 C32.03,-15.62 29.28,-18.28 22.38,-19.84 C22.38,-19.84 15.91,-21.25 15.91,-21.25 C12.81,-21.97 11.31,-23.12 11.31,-24.84 C11.31,-27.06 13.5,-28.53 16.78,-28.53 C20.28,-28.53 22.56,-26.81 22.75,-24.09 C22.75,-24.09 31.19,-24.09 31.19,-24.09 C31.06,-30.66 25.41,-35 16.91,-35 C8.19,-35 2.5,-30.75 2.5,-24.28z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,532.6655883789062,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M5.53,0 C5.53,0 14.66,0 14.66,0 C14.66,0 14.66,-27.5 14.66,-27.5 C14.66,-27.5 20.91,-27.5 20.91,-27.5 C20.91,-27.5 20.91,-34.41 20.91,-34.41 C20.91,-34.41 14.47,-34.41 14.47,-34.41 C14.47,-34.41 14.47,-36.94 14.47,-36.94 C14.5,-39.34 15.62,-40.38 18.38,-40.38 C19.38,-40.38 20.22,-40.28 20.87,-40.16 C20.87,-40.16 20.87,-46.5 20.87,-46.5 C19.62,-46.69 18.22,-46.81 16.66,-46.81 C8.69,-46.81 5.53,-44.09 5.53,-37.47 C5.53,-37.47 5.53,-34.41 5.53,-34.41 C5.53,-34.41 0.87,-34.41 0.87,-34.41 C0.87,-34.41 0.87,-27.5 0.87,-27.5 C0.87,-27.5 5.53,-27.5 5.53,-27.5 C5.53,-27.5 5.53,0 5.53,0z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,555.6031494140625,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M34.63,-34.41 C34.63,-34.41 25.5,-34.41 25.5,-34.41 C25.5,-34.41 25.5,-14.81 25.5,-14.81 C25.5,-10.16 22.84,-7.09 18.59,-7.09 C14.38,-7.09 12.06,-9.59 12.06,-14.25 C12.06,-14.25 12.06,-34.41 12.06,-34.41 C12.06,-34.41 2.94,-34.41 2.94,-34.41 C2.94,-34.41 2.94,-12.34 2.94,-12.34 C2.94,-4.34 7.84,0.5 15.28,0.5 C20.5,0.5 23.88,-1.91 25.62,-6.22 C25.62,-6.22 25.81,-6.22 25.81,-6.22 C25.81,-6.22 25.81,0 25.81,0 C25.81,0 34.63,0 34.63,0 C34.63,0 34.63,-34.41 34.63,-34.41z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,593.3247680664062,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M3.34,0 C3.34,0 12.47,0 12.47,0 C12.47,0 12.47,-47.25 12.47,-47.25 C12.47,-47.25 3.34,-47.25 3.34,-47.25 C3.34,-47.25 3.34,0 3.34,0z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>

              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Success
