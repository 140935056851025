import React, { useRef, useState } from 'react'
import PageTitle from '../Components/PageTitle'
import axios from 'axios';
import Toast, { showToast } from '../Components/Toast';
import { NavLink } from 'react-router-dom';

function ContactUs() {
  document.title = "Contact Us - Roshni Education Trust"

  const server = process.env.REACT_APP_APIPATH
  const [values, setvalues] = useState({
    name: "",
    emailId: "",
    mobileNo: "",
    message: ""
  });
  const maxChars = 200;

  const [IsLoading, setIsLoading] = useState(false)

  const refs = {
    name: useRef(),
    emailId: useRef(),
    mobileNo: useRef(),
    message: useRef()
  }

  const ValidateControls = () => {
    if (values.name === '') {
      refs.name.current.focus();
      showToast("Please enter your name", "error");
      return false;
    } else if (values.emailId === '') {
      refs.emailId.current.focus();
      showToast("Please enter your email", "error");
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.emailId)) {
      refs.emailId.current.focus();
      showToast("Please enter a valid email", "error");
      return false;
    } else if (values.mobileNo === '') {
      refs.mobileNo.current.focus();
      showToast("Please enter your mobile number", "error");
      return false;
    } else if (!/^[0-9]{10}$/.test(values.mobileNo)) {
      refs.mobileNo.current.focus();
      showToast("Please enter a valid 10 digit mobile number", "error");
      return false;
    } else if (values.message === '') {
      refs.message.current.focus();
      showToast("Please enter your message", "error");
      return false;
    }
    return true;
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(prev => true);
    if (ValidateControls()) {
      var response = await axios.post(`${server}api/Inquiry`, values);
      if (response.status === 200) {
        setvalues({
          name: "",
          emailId: "",
          mobileNo: "",
          message: ""
        });
        showToast("Message sent successfully", "success");
      }
    }
    setIsLoading(prev => false);
  }

  const onChange = async (e) => {
    setvalues({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <>
      <Toast />
      <PageTitle title="Contact Us" />
      <main id="body-content">
        <section class="wide-tb-100 pb-0">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-8 col-md-12">
                <h1 class="heading-main">
                  <small>Get In Touch</small>
                  Contact With Us
                </h1>
                <p>The secret to happiness lies in helping others. Never underestimate the difference YOU can make in the
                  lives of the poor, the abused and the helpless. Spread sunshine in their lives no matter what the weather
                  may be.</p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-lg-8 col-md-12 order-lg-last">
                <div class="contact-wrap">
                  <div class="contact-icon-xl">
                    <i class="charity-love_hearts"></i>
                  </div>
                  <div id="sucessmessage"> </div>
                  <form id="contact-form" method="post" onSubmit={SubmitHandler}>
                    <div class="row">
                      <div class="col-md-6 mb-0">
                        <div class="form-group">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className='form-control'
                            value={values.name}
                            onChange={onChange}
                            ref={refs.name}
                            maxLength={50}
                          />
                        </div>
                      </div>
                      <div class="col-md-6 mb-0">
                        <div class="form-group">
                          <input
                            type="text"
                            name="mobileNo"
                            placeholder="Mobile No"
                            className='form-control'
                            value={values.mobileNo}
                            onChange={onChange}
                            maxLength={10}
                            ref={refs.mobileNo}
                          />
                        </div>
                      </div>
                      <div class="col-md-12 mb-0">
                        <div class="form-group">
                          <input
                            type="email"
                            name="emailId"
                            placeholder="Email"
                            className='form-control'
                            value={values.emailId}
                            onChange={onChange}
                            ref={refs.emailId}
                            maxLength={50}
                          />
                        </div>
                      </div>
                      <div class="col-md-12 mb-0">
                        <div class="form-group">
                          <textarea
                            name="message"
                            placeholder="Message.."
                            className='form-control'
                            value={values.message}
                            onChange={onChange}
                            ref={refs.message}
                            rows={5}
                            maxLength={maxChars}
                          />
                          <div className={`contactMesssageCounter ${values.message.length >= maxChars ? 'warning' : ''}`}>{maxChars - values.message.length} characters remaining</div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <button type="submit" className="btn btn-primary text-nowrap" disabled={IsLoading}>
                          {IsLoading ? <div class="spinner-border  text-secondary">
                            <span class="visually-hidden">Loading...</span>
                          </div> : "Send Message"
                          }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-4 col-md-12">

                <div class="icon-box-4 bg-orange mb-4">
                  <i data-feather="map-pin"></i>
                  <h3>Our Address</h3>
                  <div>Godha Street, Opp. LIC Building, Near Khalifa Street, Nanpura, Surat</div>
                </div>


                <div class="icon-box-4 bg-green mb-4">
                  <i data-feather="phone"></i>
                  <h3>Phone Number</h3>


                  <div><a href="tel:+917861809679">+91 78618 09679 (Roshni)</a><br /><a href="tel:+916354904216">+91 63549 04216 (Rumi)</a></div>
                </div>


                <div class="icon-box-4 bg-gray mb-4">
                  <i data-feather="mail"></i>
                  <h3>Email Address</h3>
                  <div><a href="mailto:info@roshnieducationtrust.org">info@roshnieducationtrust.org
                  </a></div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section class="wide-tb-100">
          <div class="map-frame">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.1063205056157!2d72.81597721222886!3d21.18793496713577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f5c48c04457%3A0x1a44d8d77a3ac4ee!2sRumi%20Nursury%20School!5e0!3m2!1sen!2sin!4v1705604623987!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-center">
                <div class="callout-style-side-img d-lg-flex align-items-center top-broken-grid mb-0"  style={{borderRadius:"10px",width:"fit-content",marginTop:"-52px"}}>
                  <div class="img-callout ps-3">
                    <img src="./ROSHNI-LOGO.png" alt="" />
                  </div>
                  <div class="text-callout">
                    <div class="d-md-flex align-items-center">
                      <div class="heading-main mb-0">
                        <h2>Let Us Come Together To Make A Difference</h2>
                      </div>
                      <div class="icon">
                        <NavLink to="/donate-us" className="btn btn-default">Donate Now</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default ContactUs
