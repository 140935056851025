import React from 'react'
import { NavLink } from 'react-router-dom'

function Failure() {
  return (
    <div>
      <main id="body-content" className="w-100 error-style">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <h1 className="heading-main">
                Payment Failed !
                <small> Please Contact Us if Amount has been Deducted From Your Account !</small>
              </h1>
              <NavLink className="btn btn-default mt-4" to="/donate-us">
                Try Again
              </NavLink>
              <NavLink className="btn btn-secondary mt-4" to="/">
                Back to home
              </NavLink>
            </div>
            <div className="col-lg-9">
              <div className="text-center error-img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1920 1080"
                  width={1920}
                  height={1080}
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    width: "100%",
                    height: "100%",
                    transform: "translate3d(0px, 0px, 0px)",
                    contentVisibility: "visible"
                  }}
                >
                  <defs>
                    <clipPath id="__lottie_element_166">
                      <rect width={1920} height={1080} x={0} y={0} />
                    </clipPath>
                  </defs>
                  <g clipPath="url(#__lottie_element_166)">
                    <g
                      fill="rgb(255,78,78)"
                      fontSize={64}
                      fontFamily="SF UI Display"
                      fontStyle="normal"
                      fontWeight={700}
                      aria-label="Payment Failed"
                      transform="matrix(1,0,0,1,733.125,893)"
                      opacity={1}
                      style={{ display: "block" }}
                    >
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,0,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M13.19,-37.62 C13.19,-37.62 20.06,-37.62 20.06,-37.62 C25.47,-37.62 28.59,-34.75 28.59,-29.62 C28.59,-24.47 25.44,-21.59 20.03,-21.59 C20.03,-21.59 13.19,-21.59 13.19,-21.59 C13.19,-21.59 13.19,-37.62 13.19,-37.62z M3.75,-45.09 C3.75,-45.09 3.75,0 3.75,0 C3.75,0 13.19,0 13.19,0 C13.19,0 13.19,-14.22 13.19,-14.22 C13.19,-14.22 22.09,-14.22 22.09,-14.22 C31.59,-14.22 38.19,-20.41 38.19,-29.66 C38.19,-38.94 31.81,-45.09 22.56,-45.09 C22.56,-45.09 3.75,-45.09 3.75,-45.09z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,40,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M13.28,0.41 C17.69,0.41 21.94,-1.72 23.81,-5.31 C23.81,-5.31 24,-5.31 24,-5.31 C24,-5.31 24,0 24,0 C24,0 32.81,0 32.81,0 C32.81,0 32.81,-23.59 32.81,-23.59 C32.81,-30.47 27.03,-35 18.19,-35 C9.59,-35 3.66,-30.47 3.41,-23.91 C3.41,-23.91 11.75,-23.91 11.75,-23.91 C12.19,-26.41 14.5,-28.16 17.81,-28.16 C21.56,-28.16 23.84,-26.34 23.84,-23.03 C23.84,-23.03 23.84,-20.75 23.84,-20.75 C23.84,-20.75 15.25,-20.22 15.25,-20.22 C6.47,-19.69 1.69,-16.16 1.69,-9.91 C1.69,-3.75 6.66,0.41 13.28,0.41z M16.25,-6.25 C12.94,-6.25 10.78,-7.84 10.78,-10.38 C10.78,-12.91 12.81,-14.41 16.63,-14.66 C16.63,-14.66 23.84,-15.16 23.84,-15.16 C23.84,-15.16 23.84,-12.63 23.84,-12.63 C23.84,-8.88 20.47,-6.25 16.25,-6.25z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,75.84639739990234,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M7.47,12.56 C16.06,12.56 20.28,9.47 23.22,0.69 C23.22,0.69 35.13,-34.41 35.13,-34.41 C35.13,-34.41 25.5,-34.41 25.5,-34.41 C25.5,-34.41 18.03,-7.41 18.03,-7.41 C18.03,-7.41 17.84,-7.41 17.84,-7.41 C17.84,-7.41 10.41,-34.41 10.41,-34.41 C10.41,-34.41 0.41,-34.41 0.41,-34.41 C0.41,-34.41 12.47,0.22 12.47,0.22 C12.47,0.22 12.16,1.53 12.16,1.53 C11.44,4.28 9.47,5.47 6.06,5.47 C5.69,5.47 4.31,5.44 4,5.37 C4,5.37 4,12.44 4,12.44 C4.34,12.5 7.13,12.56 7.47,12.56z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,111.81439971923828,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M3.09,0 C3.09,0 12.22,0 12.22,0 C12.22,0 12.22,-20.69 12.22,-20.69 C12.22,-24.59 14.72,-27.34 18.31,-27.34 C21.84,-27.34 23.97,-25.13 23.97,-21.44 C23.97,-21.44 23.97,0 23.97,0 C23.97,0 32.78,0 32.78,0 C32.78,0 32.78,-20.81 32.78,-20.81 C32.78,-24.72 35.19,-27.34 38.78,-27.34 C42.5,-27.34 44.53,-25.13 44.53,-21.09 C44.53,-21.09 44.53,0 44.53,0 C44.53,0 53.66,0 53.66,0 C53.66,0 53.66,-23.37 53.66,-23.37 C53.66,-30.31 49.13,-34.91 42.38,-34.91 C37.37,-34.91 33.25,-32.19 31.69,-27.88 C31.69,-27.88 31.5,-27.88 31.5,-27.88 C30.34,-32.38 26.84,-34.91 21.91,-34.91 C17.31,-34.91 13.56,-32.31 12.09,-28.16 C12.09,-28.16 11.91,-28.16 11.91,-28.16 C11.91,-28.16 11.91,-34.41 11.91,-34.41 C11.91,-34.41 3.09,-34.41 3.09,-34.41 C3.09,-34.41 3.09,0 3.09,0z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,168.4095916748047,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M25.88,-10.5 C25,-7.84 22.28,-6.22 18.84,-6.22 C14.03,-6.22 10.81,-9.56 10.81,-14.53 C10.81,-14.53 10.81,-15.09 10.81,-15.09 C10.81,-15.09 34.56,-15.09 34.56,-15.09 C34.56,-15.09 34.56,-17.91 34.56,-17.91 C34.56,-28.38 28.25,-35 18.34,-35 C8.25,-35 1.81,-28 1.81,-17.06 C1.81,-6.12 8.22,0.59 18.69,0.59 C27.06,0.59 33.28,-3.84 34.28,-10.5 C34.28,-10.5 25.88,-10.5 25.88,-10.5z M18.44,-28.19 C22.66,-28.19 25.59,-25.25 25.75,-20.75 C25.75,-20.75 10.91,-20.75 10.91,-20.75 C11.22,-25.16 14.28,-28.19 18.44,-28.19z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,204.78720092773438,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M3.09,0 C3.09,0 12.22,0 12.22,0 C12.22,0 12.22,-19.62 12.22,-19.62 C12.22,-24.19 14.84,-27.31 19.22,-27.31 C23.53,-27.31 25.66,-24.72 25.66,-20.16 C25.66,-20.16 25.66,0 25.66,0 C25.66,0 34.78,0 34.78,0 C34.78,0 34.78,-22.06 34.78,-22.06 C34.78,-30.09 30.47,-34.91 22.84,-34.91 C17.56,-34.91 14.06,-32.59 12.09,-28.25 C12.09,-28.25 11.91,-28.25 11.91,-28.25 C11.91,-28.25 11.91,-34.41 11.91,-34.41 C11.91,-34.41 3.09,-34.41 3.09,-34.41 C3.09,-34.41 3.09,0 3.09,0z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,242.50880432128906,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M5.53,-42.06 C5.53,-42.06 5.53,-34.41 5.53,-34.41 C5.53,-34.41 0.87,-34.41 0.87,-34.41 C0.87,-34.41 0.87,-27.5 0.87,-27.5 C0.87,-27.5 5.53,-27.5 5.53,-27.5 C5.53,-27.5 5.53,-9.28 5.53,-9.28 C5.53,-2.56 8.66,0.12 16.63,0.12 C18.47,0.12 20.09,-0.06 21.03,-0.25 C21.03,-0.25 21.03,-7 21.03,-7 C20.47,-6.91 19.56,-6.84 18.75,-6.84 C15.97,-6.84 14.66,-8.13 14.66,-10.78 C14.66,-10.78 14.66,-27.5 14.66,-27.5 C14.66,-27.5 21.03,-27.5 21.03,-27.5 C21.03,-27.5 21.03,-34.41 21.03,-34.41 C21.03,-34.41 14.66,-34.41 14.66,-34.41 C14.66,-34.41 14.66,-42.06 14.66,-42.06 C14.66,-42.06 5.53,-42.06 5.53,-42.06z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,265.66400146484375,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g style={{ display: "none" }} />
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,279.41119384765625,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M13.19,0 C13.19,0 13.19,-17.75 13.19,-17.75 C13.19,-17.75 31.53,-17.75 31.53,-17.75 C31.53,-17.75 31.53,-25.22 31.53,-25.22 C31.53,-25.22 13.19,-25.22 13.19,-25.22 C13.19,-25.22 13.19,-37.31 13.19,-37.31 C13.19,-37.31 33.28,-37.31 33.28,-37.31 C33.28,-37.31 33.28,-45.09 33.28,-45.09 C33.28,-45.09 3.75,-45.09 3.75,-45.09 C3.75,-45.09 3.75,0 3.75,0 C3.75,0 13.19,0 13.19,0z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,314.50238037109375,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M13.28,0.41 C17.69,0.41 21.94,-1.72 23.81,-5.31 C23.81,-5.31 24,-5.31 24,-5.31 C24,-5.31 24,0 24,0 C24,0 32.81,0 32.81,0 C32.81,0 32.81,-23.59 32.81,-23.59 C32.81,-30.47 27.03,-35 18.19,-35 C9.59,-35 3.66,-30.47 3.41,-23.91 C3.41,-23.91 11.75,-23.91 11.75,-23.91 C12.19,-26.41 14.5,-28.16 17.81,-28.16 C21.56,-28.16 23.84,-26.34 23.84,-23.03 C23.84,-23.03 23.84,-20.75 23.84,-20.75 C23.84,-20.75 15.25,-20.22 15.25,-20.22 C6.47,-19.69 1.69,-16.16 1.69,-9.91 C1.69,-3.75 6.66,0.41 13.28,0.41z M16.25,-6.25 C12.94,-6.25 10.78,-7.84 10.78,-10.38 C10.78,-12.91 12.81,-14.41 16.63,-14.66 C16.63,-14.66 23.84,-15.16 23.84,-15.16 C23.84,-15.16 23.84,-12.63 23.84,-12.63 C23.84,-8.88 20.47,-6.25 16.25,-6.25z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,350.3487854003906,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M7.81,-38.16 C10.53,-38.16 12.75,-40.22 12.75,-42.94 C12.75,-45.66 10.53,-47.69 7.81,-47.69 C5.13,-47.69 2.91,-45.66 2.91,-42.94 C2.91,-40.22 5.13,-38.16 7.81,-38.16z M3.25,0 C3.25,0 12.38,0 12.38,0 C12.38,0 12.38,-34.41 12.38,-34.41 C12.38,-34.41 3.25,-34.41 3.25,-34.41 C3.25,-34.41 3.25,0 3.25,0z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,365.97119140625,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M3.34,0 C3.34,0 12.47,0 12.47,0 C12.47,0 12.47,-47.25 12.47,-47.25 C12.47,-47.25 3.34,-47.25 3.34,-47.25 C3.34,-47.25 3.34,0 3.34,0z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,381.75360107421875,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M25.88,-10.5 C25,-7.84 22.28,-6.22 18.84,-6.22 C14.03,-6.22 10.81,-9.56 10.81,-14.53 C10.81,-14.53 10.81,-15.09 10.81,-15.09 C10.81,-15.09 34.56,-15.09 34.56,-15.09 C34.56,-15.09 34.56,-17.91 34.56,-17.91 C34.56,-28.38 28.25,-35 18.34,-35 C8.25,-35 1.81,-28 1.81,-17.06 C1.81,-6.12 8.22,0.59 18.69,0.59 C27.06,0.59 33.28,-3.84 34.28,-10.5 C34.28,-10.5 25.88,-10.5 25.88,-10.5z M18.44,-28.19 C22.66,-28.19 25.59,-25.25 25.75,-20.75 C25.75,-20.75 10.91,-20.75 10.91,-20.75 C11.22,-25.16 14.28,-28.19 18.44,-28.19z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeMiterlimit={4}
                        transform="matrix(1,0,0,1,418.1312255859375,0)"
                        opacity={1}
                        style={{ display: "inherit" }}
                      >
                        <g>
                          <g
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <path d=" M18.94,-27.44 C23.66,-27.44 26.72,-23.41 26.72,-17.19 C26.72,-10.97 23.66,-6.97 18.94,-6.97 C14.19,-6.97 11.16,-10.91 11.16,-17.22 C11.16,-23.47 14.19,-27.44 18.94,-27.44z M15.91,0.41 C20.97,0.41 24.94,-2.09 26.63,-5.88 C26.63,-5.88 26.78,-5.88 26.78,-5.88 C26.78,-5.88 26.78,0 26.78,0 C26.78,0 35.78,0 35.78,0 C35.78,0 35.78,-47.25 35.78,-47.25 C35.78,-47.25 26.66,-47.25 26.66,-47.25 C26.66,-47.25 26.66,-28.53 26.66,-28.53 C26.66,-28.53 26.47,-28.53 26.47,-28.53 C24.75,-32.34 20.91,-34.81 15.94,-34.81 C7.37,-34.81 1.81,-28.03 1.81,-17.25 C1.81,-6.41 7.34,0.41 15.91,0.41z" />
                            <g
                              opacity={1}
                              transform="matrix(0.6399999856948853,0,0,0.6399999856948853,0,0)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      style={{ display: "block" }}
                      transform="matrix(3.7037100791931152,0,0,3.7037100791931152,960,540)"
                      opacity={1}
                    >
                      <g opacity={1} transform="matrix(1,0,0,1,66.5,-49)">
                        <path
                          fill="rgb(255,163,163)"
                          fillOpacity={1}
                          d=" M0,-7.5 C4.139249801635742,-7.5 7.5,-4.139249801635742 7.5,0 C7.5,4.139249801635742 4.139249801635742,7.5 0,7.5 C-4.139249801635742,7.5 -7.5,4.139249801635742 -7.5,0 C-7.5,-4.139249801635742 -4.139249801635742,-7.5 0,-7.5z"
                        />
                        <path
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          fillOpacity={0}
                          strokeMiterlimit={4}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth={5}
                          d=" M0,-7.5 C4.139249801635742,-7.5 7.5,-4.139249801635742 7.5,0 C7.5,4.139249801635742 4.139249801635742,7.5 0,7.5 C-4.139249801635742,7.5 -7.5,4.139249801635742 -7.5,0 C-7.5,-4.139249801635742 -4.139249801635742,-7.5 0,-7.5z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-51,48.5)">
                        <path
                          fill="rgb(255,163,163)"
                          fillOpacity={1}
                          d=" M0,-8.5 C4.691150188446045,-8.5 8.5,-4.691150188446045 8.5,0 C8.5,4.691150188446045 4.691150188446045,8.5 0,8.5 C-4.691150188446045,8.5 -8.5,4.691150188446045 -8.5,0 C-8.5,-4.691150188446045 -4.691150188446045,-8.5 0,-8.5z"
                        />
                        <path
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          fillOpacity={0}
                          strokeMiterlimit={4}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth={5}
                          d=" M0,-8.5 C4.691150188446045,-8.5 8.5,-4.691150188446045 8.5,0 C8.5,4.691150188446045 4.691150188446045,8.5 0,8.5 C-4.691150188446045,8.5 -8.5,4.691150188446045 -8.5,0 C-8.5,-4.691150188446045 -4.691150188446045,-8.5 0,-8.5z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-49.5,-57.5)">
                        <path
                          fill="rgb(255,163,163)"
                          fillOpacity={1}
                          d=" M0,-6 C3.3113999366760254,-6 6,-3.3113999366760254 6,0 C6,3.3113999366760254 3.3113999366760254,6 0,6 C-3.3113999366760254,6 -6,3.3113999366760254 -6,0 C-6,-3.3113999366760254 -3.3113999366760254,-6 0,-6z"
                        />
                        <path
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          fillOpacity={0}
                          strokeMiterlimit={4}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth={5}
                          d=" M0,-6 C3.3113999366760254,-6 6,-3.3113999366760254 6,0 C6,3.3113999366760254 3.3113999366760254,6 0,6 C-3.3113999366760254,6 -6,3.3113999366760254 -6,0 C-6,-3.3113999366760254 -3.3113999366760254,-6 0,-6z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,59.75,43.25)">
                        <path
                          fill="rgb(255,163,163)"
                          fillOpacity={1}
                          d=" M0,-6.25 C3.4493749141693115,-6.25 6.25,-3.4493749141693115 6.25,0 C6.25,3.4493749141693115 3.4493749141693115,6.25 0,6.25 C-3.4493749141693115,6.25 -6.25,3.4493749141693115 -6.25,0 C-6.25,-3.4493749141693115 -3.4493749141693115,-6.25 0,-6.25z"
                        />
                        <path
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          fillOpacity={0}
                          strokeMiterlimit={4}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth={5}
                          d=" M0,-6.25 C3.4493749141693115,-6.25 6.25,-3.4493749141693115 6.25,0 C6.25,3.4493749141693115 3.4493749141693115,6.25 0,6.25 C-3.4493749141693115,6.25 -6.25,3.4493749141693115 -6.25,0 C-6.25,-3.4493749141693115 -3.4493749141693115,-6.25 0,-6.25z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-6.75,68.75)">
                        <path
                          fill="rgb(255,163,163)"
                          fillOpacity={1}
                          d=" M0,-4.75 C2.6215250492095947,-4.75 4.75,-2.6215250492095947 4.75,0 C4.75,2.6215250492095947 2.6215250492095947,4.75 0,4.75 C-2.6215250492095947,4.75 -4.75,2.6215250492095947 -4.75,0 C-4.75,-2.6215250492095947 -2.6215250492095947,-4.75 0,-4.75z"
                        />
                        <path
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          fillOpacity={0}
                          strokeMiterlimit={4}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth={5}
                          d=" M0,-4.75 C2.6215250492095947,-4.75 4.75,-2.6215250492095947 4.75,0 C4.75,2.6215250492095947 2.6215250492095947,4.75 0,4.75 C-2.6215250492095947,4.75 -4.75,2.6215250492095947 -4.75,0 C-4.75,-2.6215250492095947 -2.6215250492095947,-4.75 0,-4.75z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-65.25,-37.75)">
                        <path
                          fill="rgb(255,163,163)"
                          fillOpacity={1}
                          d=" M0,-3.75 C2.069624900817871,-3.75 3.75,-2.069624900817871 3.75,0 C3.75,2.069624900817871 2.069624900817871,3.75 0,3.75 C-2.069624900817871,3.75 -3.75,2.069624900817871 -3.75,0 C-3.75,-2.069624900817871 -2.069624900817871,-3.75 0,-3.75z"
                        />
                        <path
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          fillOpacity={0}
                          strokeMiterlimit={4}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth={5}
                          d=" M0,-3.75 C2.069624900817871,-3.75 3.75,-2.069624900817871 3.75,0 C3.75,2.069624900817871 2.069624900817871,3.75 0,3.75 C-2.069624900817871,3.75 -3.75,2.069624900817871 -3.75,0 C-3.75,-2.069624900817871 -2.069624900817871,-3.75 0,-3.75z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,42,-63)">
                        <path
                          fill="rgb(255,163,163)"
                          fillOpacity={1}
                          d=" M0,-4.5 C2.4835500717163086,-4.5 4.5,-2.4835500717163086 4.5,0 C4.5,2.4835500717163086 2.4835500717163086,4.5 0,4.5 C-2.4835500717163086,4.5 -4.5,2.4835500717163086 -4.5,0 C-4.5,-2.4835500717163086 -2.4835500717163086,-4.5 0,-4.5z"
                        />
                        <path
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          fillOpacity={0}
                          strokeMiterlimit={4}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth={5}
                          d=" M0,-4.5 C2.4835500717163086,-4.5 4.5,-2.4835500717163086 4.5,0 C4.5,2.4835500717163086 2.4835500717163086,4.5 0,4.5 C-2.4835500717163086,4.5 -4.5,2.4835500717163086 -4.5,0 C-4.5,-2.4835500717163086 -2.4835500717163086,-4.5 0,-4.5z"
                        />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,-73,-4)">
                        <path
                          fill="rgb(255,163,163)"
                          fillOpacity={1}
                          d=" M0,-3 C1.6556999683380127,-3 3,-1.6556999683380127 3,0 C3,1.6556999683380127 1.6556999683380127,3 0,3 C-1.6556999683380127,3 -3,1.6556999683380127 -3,0 C-3,-1.6556999683380127 -1.6556999683380127,-3 0,-3z"
                        />
                        <path
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          fillOpacity={0}
                          strokeMiterlimit={4}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth={5}
                          d=" M0,-3 C1.6556999683380127,-3 3,-1.6556999683380127 3,0 C3,1.6556999683380127 1.6556999683380127,3 0,3 C-1.6556999683380127,3 -3,1.6556999683380127 -3,0 C-3,-1.6556999683380127 -1.6556999683380127,-3 0,-3z"
                        />
                      </g>
                    </g>
                    <g
                      style={{ display: "none" }}
                      transform="matrix(5.843883514404297,0,0,5.843883514404297,1315.015869140625,561.7974853515625)"
                      opacity="0.0018706703421125326"
                    >
                      <g opacity={1} transform="matrix(1,0,0,1,-60.75,-4.25)">
                        <path
                          fill="rgb(255,163,163)"
                          fillOpacity={1}
                          d=" M0,-61.25 C33.80387496948242,-61.25 61.25,-33.80387496948242 61.25,0 C61.25,33.80387496948242 33.80387496948242,61.25 0,61.25 C-33.80387496948242,61.25 -61.25,33.80387496948242 -61.25,0 C-61.25,-33.80387496948242 -33.80387496948242,-61.25 0,-61.25z"
                        />
                      </g>
                    </g>
                    <g
                      style={{ display: "block" }}
                      transform="matrix(5.53370475769043,0,0,5.53370475769043,1303.0897216796875,560.4016723632812)"
                      opacity={1}
                    >
                      <g opacity={1} transform="matrix(1,0,0,1,-62,-4.5)">
                        <path
                          fill="rgb(255,77,77)"
                          fillOpacity={1}
                          d=" M0,-40 C22.076000213623047,-40 40,-22.076000213623047 40,0 C40,22.076000213623047 22.076000213623047,40 0,40 C-22.076000213623047,40 -40,22.076000213623047 -40,0 C-40,-22.076000213623047 -22.076000213623047,-40 0,-40z"
                        />
                      </g>
                    </g>
                    <g
                      transform="matrix(1.1954799890518188,0,0,1.1954799890518188,582.552978515625,147.38333129882812)"
                      opacity={1}
                      style={{ display: "block" }}
                    >
                      <g
                        opacity={1}
                        transform="matrix(1,0,0,1,277.7900085449219,280.9410095214844)"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity={0}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth="28.346"
                          d=" M114.4010009765625,118.2490005493164 C114.4010009765625,118.2490005493164 18.329999923706055,22.17799949645996 -28.55900001525879,-24.709999084472656"
                        />
                      </g>
                    </g>
                    <g
                      transform="matrix(1.1954799890518188,0,0,1.1954799890518188,577.93017578125,255.0804443359375)"
                      opacity={1}
                      style={{ display: "block" }}
                    >
                      <g
                        opacity={1}
                        transform="matrix(1,0,0,1,277.7900085449219,280.7510070800781)"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity={0}
                          stroke="rgb(255,255,255)"
                          strokeOpacity={1}
                          strokeWidth="28.346"
                          d=" M114.4010009765625,-118.25 C114.4010009765625,-118.25 18.329999923706055,-22.179000854492188 -28.55900001525879,24.709999084472656"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Failure
