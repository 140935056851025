import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    pan: "",
    amount: "",
    hash: "",
    txnid: ""
};

const paymentDetailsReducer = createSlice({
    name: "paymentDetails",
    initialState,
    reducers: {
        updateAmount: (state, action) => {
            const amount = action.payload;
            if (amount > 0) {
                return { ...state, amount: amount };
            }
            return state;
        },
        updateDetails: (state, action) => {
            const payload = action.payload;
            return {
                ...state,
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                mobileNo: payload.mobileNo,
                pan: payload.pan
            };
        },
        updateFirstName: (state, action) => {
            const firstName = action.payload;
            return {
                ...state,
                firstName: firstName
            }
        },
        updateLastName: (state, action) => {
            const lastName = action.payload;
            return {
                ...state,
                lastName: lastName
            }
        },
        updateMobileNo: (state, action) => {
            const mobileNo = action.payload;
            return {
                ...state,
                mobileNo: mobileNo
            }
        },
        updateEmail: (state, action) => {
            const email = action.payload;
            return {
                ...state,
                email: email
            }
        },
        updatePan: (state, action) => {
            const pan = action.payload;
            return {
                ...state,
                pan: pan
            }
        },
        updateHash: (state, action) => {
            const hash = action.payload;
            return {
                ...state,
                hash: hash
            }
        },
        updateTXNID: (state, action) => {
            const txnid = action.payload;
            return {
                ...state,
                txnid: txnid
            }
        }
    }
});

export const { updateAmount, updateDetails, updateFirstName, updateLastName, updateMobileNo, updateEmail, updatePan, updateHash, updateTXNID } = paymentDetailsReducer.actions;
export const reducer = paymentDetailsReducer.reducer;