import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { NavLink } from 'react-router-dom';

function CauseWithEvents({ id }) {
    const server = process.env.REACT_APP_APIPATH;
    const [data, setData] = useState({});
    const [Html, setHtml] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const fillData = async () => {
        try {
            const response = await axios.get(`${server}api/Client/cause/${id}`);
            const json = await response.data;
            setData(json);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const fillDataHtml = async () => {
        try {

            const response = await axios.get(`${server}api/Client/cause/${id}/page`);
            const json = await response.data;
            setHtml(json);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fillData();
        fillDataHtml();
    }, []);

    function convertDateToIndianFormat(utcTimestamp) {
        const date = new Date(utcTimestamp);

        // Calculate the offset in minutes
        const offsetInMinutes = date.getTimezoneOffset();

        // Adjust for IST by subtracting the offset from the Date object
        const istDateTime = new Date(date.getTime() - offsetInMinutes * 60000);

        return istDateTime;
    }

    function replaceSpaceWithDash(input) {
        return input.toLowerCase().split(" ").join("-");
    }

    return (<>
        {isLoading && <Skeleton className='wide-tb-50' />}
        {!isLoading && <>
                <div dangerouslySetInnerHTML={{ __html: Html }}>
                </div>
        </>}
        <br />
        {!isLoading && data.causePhotos && data.causePhotos.length > 0 && <>
            <section className="wide-tb-30">
                <div className="container">
                    <div className="row img-gallery">
                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} >
                            <Masonry gutter="10px">
                                {!isLoading &&
                                    data.causePhotos.map(photo => {
                                        return (
                                            <div key={photo.rowNumber} >
                                                <div className="img-gallery-item">
                                                    <img src={server + photo.photo} alt="" />
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>
                </div>
            </section>
        </>}
        {!isLoading && <section class="wide-tb-30 bg-scroll pos-rel callout-style-1" style={{ backgroundImage: `url(${server + data.photo})` }}>
            <div class="bg-overlay blue opacity-80"></div>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <h1 class="heading-main light-mode">
                            <small>Events At</small>
                            {data.causeName}
                        </h1>
                    </div>
                    <div class="col-sm-12 text-md-end">
                        <NavLink to="/donate-us" className="btn btn-default">Donate Now</NavLink>
                    </div>
                </div>
            </div>
        </section>}
        <br />
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-sm-1">
            {isLoading && Array.from({ length: 6 }, () => {
                return (
                    <div className="col mb-5">
                        <Skeleton height={390} />
                    </div>
                );
            })
            }
            {!isLoading && data.events && data.events.map(item => {
                var date = new moment(convertDateToIndianFormat(item.eventDate));
                return (
                    <div className="col mb-5">
                        <div className="event-wrap-alternate">
                            <NavLink to={"/events/" + replaceSpaceWithDash(item.eventName)}>
                                <div className="img-wrap">
                                    <div className="date-box">
                                        {date.date()} <span>{date.format("MMM")}</span>
                                    </div>
                                    <img
                                        src={server + item.photo} alt={item.eventName}
                                    />
                                    <div className="content-wrap">
                                        <h3>
                                            <NavLink to={"/events/" + replaceSpaceWithDash(item.eventName)}>
                                                {item.eventName}
                                            </NavLink>
                                        </h3>
                                        <div className="event-details">
                                            <div>
                                                <i data-feather="clock" /> {date.format("dddd")}, {date.format('h:mm a')}
                                            </div>
                                            <div>
                                                <i data-feather="map-pin" /> At {item.causeName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>)
            })
            }
        </div>
    </>
    )
}

export default CauseWithEvents
