import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from './Pages/Home'
import AboutUs from './Pages/AboutUs'
import Causes from './Pages/Causes'
import Events from './Pages/Events'
import ContactUs from './Pages/ContactUs'
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import Donate from './Pages/Donate'
import Success from './Pages/Success'
import Failure from './Pages/Failure'
import NotFound404 from './Pages/NotFound404'
import OurVision from './Pages/OurVision'
import Gallery from './Pages/Gallery'
// import ThemeToggler from './ThemeToggler'

function App() {
  const location = useLocation();

  useEffect(() => {
    const hiddenBar = document.querySelector('.hidden-bar');
    if (hiddenBar) {
      hiddenBar.classList.remove('visible-sidebar');
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us/*" element={<AboutUs />} />
        <Route exact path="/our-vision/*" element={<OurVision />} />
        <Route exact path="/causes/*" element={<Causes />} />
        <Route exact path="/events/*" element={<Events />} />
        <Route exact path="/gallery/*" element={<Gallery />} />
        <Route exact path="/contact/*" element={<ContactUs />} />
        <Route exact path="/donate-us/*" element={<Donate />} />
        <Route exact path="/payment-success/*" element={<Success />} />
        <Route exact path="/payment-failure/*" element={<Failure />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
      <Footer />
      {/* <ThemeToggler /> */}
    </>
  )
}

export default App
