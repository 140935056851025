import axios from 'axios';
import React, { useEffect, useState } from 'react'
import PageTitle from '../Components/PageTitle'
import CausesComp from '../Components/CausesComp'
import { Route, Routes } from 'react-router-dom';
import CauseWithEvents from '../Components/CauseWithEvents';

function Causes() {
  document.title = "Our Causes - Roshni Education Trust"
  const server = process.env.REACT_APP_APIPATH;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fillData = async () => {
    try {
      const response = await axios.get(`${server}api/Client/Causes`);
      const json = await response.data;
      setData(json);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  function replaceSpaceWithDash(input) {
    return input.toLowerCase().split(" ").join("-");
  }

  useEffect(() => {
    fillData();
  }, []);

  return (
    <div>
      <PageTitle title="Causes" />
      <main id="body-content">
        <section className="wide-tb-100">
          <div className="container">
            <Routes>
              <Route exact path='/' element={<CausesComp server={server} data={data} isLoading={isLoading} replaceSpaceWithDash={replaceSpaceWithDash} />} />
              {data.map(cause => {
                return (
                  <Route exact path={replaceSpaceWithDash(cause.causeName)} element={<CauseWithEvents id={cause.causeID} />} />
                );
              })}
            </Routes>
          </div>
        </section>
      </main>

    </div>
  )
}

export default Causes
