import React, { useEffect } from 'react'
import PageTitle from '../Components/PageTitle'
import { Route, Routes } from 'react-router-dom'
import DonationAmount from '../Components/DonationAmount'
import DonationDetails from '../Components/DonationDetails'

function Donate() {
  document.title = "Donation - Roshni Education Trust"

  return (
    <div>
      <PageTitle title="Donation" />
      <main id="body-content">
        <section className="wide-tb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12"  >
                <h1 className="heading-main mb-0" >
                  <small>Donate Directly to Our UPI</small>
                </h1>
                <img src="assets/images/UPIQR1.jpeg" alt="" srcset="" />
              </div>
              <div className="col-lg-8 col-md-12"  >
                <h1 className="heading-main mb-0" >
                  <small>Or Make a Donation through Payment Gateway</small>
                  Build Great Futures With Great Contributions
                </h1>
                <Routes>
                  <Route path="" element={<DonationAmount />} />
                  <Route path="details" element={<DonationDetails />} />
                </Routes>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default Donate;