import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

function PageTitle({ title }) {
  const location = useLocation();
  const locArray = location.pathname.split("/");

  function replaceDashWithSpace(input) {
    return input.toLowerCase().split("-").join(" ");
  }
  return (
    <section className="breadcrumbs-page-wrap">
      <div className="bg-fixed pos-rel breadcrumbs-page">
        <div className="container">
          <h1>{locArray.length <= 1 ? title : replaceDashWithSpace(locArray[locArray.length-1])}</h1>
          <nav aria-label="breadcrumb" className="breadcrumb-wrap">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/">Home</NavLink>
              </li>
              <li className={`breadcrumb-item ${locArray.length <= 1 ? "active" : ""}`} aria-current="page">
              {title}
              </li>
              {locArray.length > 2 && locArray.map((item,index)=>{
                if(index <= 1)
                return "";
                return (<li className="breadcrumb-item active" style={{textTransform:"capitalize"}} aria-current="page">
                  {replaceDashWithSpace(item)}
                  </li>);
              })}
            </ol>
          </nav>
        </div>
      </div>
    </section>
  )
}

export default PageTitle